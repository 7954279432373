import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper} from "../_helpers";


// create slice

const name = 'application';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const applicationActions = { ...slice.actions, ...extraActions };
export const applicationReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        applicationItem: [],
        applicationInfo: null,
        error: null,
        loading: false
    }
}

function createReducers() {
    return {
        resetApplication
    };

    function resetApplication(state) {
        state.applicationItem = [];
        state.applicationInfo = null;
        state.error = null;
        state.loading = false;
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/application`;

    return {
        getApplicationList: getApplicationList(),
        getApplicationDetails: getApplicationDetails(),
        searchApplicationList: searchApplicationList(),
    };

    function getApplicationList() {
        return createAsyncThunk(
            `${name}/applicationStoreDetails`,
            async (rest = {}) => await fetchWrapper.post(`${baseUrl}/get_by_org_group_id`, {orgGroup_id: '3HPC6OLO2PAI47',...rest})
        );
    }
    function getApplicationDetails() {
        return createAsyncThunk(
            `${name}/getApplicationInfo`,
            async (data) => await fetchWrapper.post(`${baseUrl}/get_details`, {application_id: data.id})
        );
    }

    function searchApplicationList() {
        return createAsyncThunk(
            `${name}/searchApplicationList`,
            async (value) => value
        );
    }
}

function createExtraReducers() {
    return {
        ...getApplicationList(),
        ...getApplicationDetails(),
        ...searchApplicationList()
    };

    function getApplicationList() {
        var { pending, fulfilled, rejected } = extraActions.getApplicationList;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.applicationItem = _.uniqBy(action.payload.data, 'app_name');
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }

    function searchApplicationList() {
        var { pending, fulfilled, rejected } = extraActions.searchApplicationList;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }

    function getApplicationDetails() {
        var { pending, fulfilled, rejected } = extraActions.getApplicationDetails;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.applicationInfo = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }

}
