import React, {useRef, useState} from 'react';
import {Field, FieldArray, Form, Formik} from 'formik';
import svgCopyImg from "../../assets/images/icon/Icon feather-copy.svg"
import svgDownloadImg from "../../assets/images/icon/Icon feather-download.svg"
import svgSquareImg from "../../assets/images/icon/Icon feather-check-square.svg"
import svgInfoImg from "../../assets/images/icon/Icon feather-info.svg"
import {CopyToClipboard} from "react-copy-to-clipboard";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "../../../_store";
import _ from "lodash";

export default function SeedPhraseModal({isOpen,handleClose,dataSeedPhrase}) {
    const seedPhrase = `${dataSeedPhrase}`.split(" ");
    const pharaseList = seedPhrase.map((item) => {
        return {name:item, selected: false}
    });
    const [value, setValue] = useState([]);
    const phraseFormRef = useRef();
    const initialValues = { pharaseList };

    const handleDownloadTxtFile = () => {
        let selectedPharaseList = [];
        phraseFormRef.current.values.pharaseList.map((item,index) => {
            if(item.selected === true){
                selectedPharaseList.push(item.name);
            }
        });
        const content = selectedPharaseList.toLocaleString();
        const element = document.createElement('a');
        const file = new Blob([content], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = 'SeedPharaseList.txt';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element)
        phraseFormRef?.current?.setSubmitting(false);
    }

    // Redux Methods
    const dispatch = useDispatch();
    const authLoading = useSelector(x => x.auth.loading);

    function _handleSubmit(values) {
        values.password = localStorage.getItem('password');
        dispatch(authActions.login({ values })).then((response) => {
            if (response) {
                document.querySelectorAll(".modal-backdrop").forEach(e => e.remove());
                localStorage.removeItem('password');
                dispatch(authActions.getUserDetails(_.get(response.payload, "data.user_id", "")));
                dispatch(authActions.getJoiningBonus());
                return handleClose(true);
            }
        });
    }

    const onCopy = () => {}

    return (
        <>
                <div className={"modal fade " + (isOpen ? 'show' : 'hide')} style={{display: isOpen ? 'block' : 'none'}} id="important" role='dialog' tabIndex={4} aria-modal='true'>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header row mx-0 border-0 shadow-none">
                            <div className="col-md-12 d-flex">
                                <img src={svgInfoImg} alt="" className="pe-2" />&nbsp;&nbsp;
                                <h4 className="modal-title text-white">Account Seed Phrase</h4>
                            </div>
                        </div>

                        <Formik initialValues={initialValues}
                                enableReinitialize={true}
                                innerRef={phraseFormRef}
                                onSubmit={values => {
                                    _handleSubmit(JSON.parse(localStorage.getItem('register')))
                                }}
                                >
                                {({ values, errors, touched, handleReset, handleChange}) => (
                                        <Form>
                                            <div className="modal-body row mx-0">
                                                <p className="text-warning fw-bold"><small>Seed Phrase</small></p>
                                                <div className="row">
                                                    <FieldArray
                                                        name="optionList"
                                                        render={({  remove, push }) => (<>
                                                                {values.pharaseList.length > 0  && values.pharaseList.map((item,index) => (
                                                                    <div className="col-md-3 my-2" key={index}>
                                                                        <div className="button rounded" onClick={(e) => {setValue(oldArray => [...oldArray,item.name] );}}>
                                                                            <Field type="checkbox" id={`pharaseList.${index}.selected`} name={`pharaseList.${index}.selected`} />
                                                                            <label className="btn d-flex justify-content-center"
                                                                                   htmlFor="abc">{item.name}{item.selected}</label>
                                                                        </div>
                                                                    </div>
                                                                ))}</>
                                                        )} />
                                                </div>
                                            </div>
                                            <div className="modal-footer border-0 mx-0">
                                                <div className="row justify-content-between w-100">
                                                    <div className="col-3">
                                                        <button type="submit" className="color-light-blue fw-bold transparent-link font-13 mt-2">keep</button>
                                                    </div>
                                                    <div className="col-9 text-center">
                                                        <CopyToClipboard
                                                            onCopy={onCopy}
                                                            text={value}>
                                                            <button type="button" className="btn border-0 bg-warning font-13 fw-bold py-2 btn-hover color-2"><img src={svgCopyImg} alt="" /><span className="ps-2">Copy</span></button>
                                                        </CopyToClipboard>
                                                        <button type="button" className="btn border-0 font-13 fw-bold py-2 ms-2 btn-hover color-4" onClick={(event) => handleDownloadTxtFile(event)}><img src={svgDownloadImg} alt="" /><span className="ps-2">Download</span></button>
                                                        <button type="submit" className="btn border-0 font-13 fw-bold py-2 ms-2 btn-hover color-1" >
                                                            {authLoading ?
                                                                <div className="spinner-border spinner-border-sm text-warning" role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div> : <>
                                                            <img src={svgSquareImg} alt="" /><span className="ps-2">Go</span></>}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                )}
                        </Formik>
                    </div>
                </div>
                <button type="button" className="btn-close rounded-circle opacity-100" data-bs-dismiss="modal" onClick={handleClose} ></button>
                </div>
        </>
    );

}
