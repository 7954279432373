import React from 'react';
import Chessboard from "chessboardjsx";
import StockFish from "../integrations/StockFish";
const BotChessGame = () => {
    return (
        <div style={boardsContainer}>
            <StockFish>
                {({position, onDrop, onDragOverSquare, onDragDrop}) => (
                    <Chessboard
                        id="stockfish"
                        position={position}
                        width={600}
                        onDrop={(move) => onDrop(move)}
                        onDragOverSquare={onDragOverSquare}
                        boardStyle={boardStyle}
                        orientation="black"
                    />
                )}
            </StockFish>
        </div>
    );
};

export default BotChessGame;

const boardsContainer = {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
};
const boardStyle = {
    borderRadius: "5px",
    boxShadow: `0 5px 15px rgba(0, 0, 0, 0.5)`
};
