import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from "react-redux";
import { profileActions } from "../../../_store";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";

import group2344Icon from "../../assets/images/icon/Group 2344.svg";

export default function ResetPinFirstModal({isOpen, handleClose, handleBack}) {

    const [error, setError] = useState(false);
    const [errMessage, setErrMessage] = useState('');

    const resetPinFirstFormRef = useRef();
    const otpValue1Ref = useRef(null);
    const otpValue2Ref = useRef(null);
    const otpValue3Ref = useRef(null);
    const otpValue4Ref = useRef(null);

    //Redux Methods
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const verifyRequestLoading = useSelector(x => x.profiles.loading);
    const verifyResponse = useSelector(x => x.profiles.verify);

    useEffect(() => {
        if (otpValue1Ref.current) {
            otpValue1Ref.current.focus();
        }
        if (isOpen) {
            const element = document.createElement("div");
            element.setAttribute("class", "modal-backdrop fade show");
            document.body.appendChild(element);
        }
    }, [otpValue1Ref, isOpen]);

    const onChange = (event, valRef) => {
        if (!/[0-9]/.test(event.key)) {
            //regOtpFormRef?.current?.setError(true);
        }
        if (/[0-9]/.test(event.target.value) && valRef) {
            valRef.current.focus();
        }
    }

    function _verifyResetPin(values) {
        Object.entries(values).map(([key,value])=> {
            if (value === '') {
                setError(true);
                setErrMessage('Please enter pin');
                return false;
            } else {
                setError(false);
                setErrMessage('');
            }
        });
        const pinData = Number(`${values.otpValue1}${values.otpValue2}${values.otpValue3}${values.otpValue4}`);
        if (pinData) {
            const values = {
                email: authUser?.email,
                auth_otp: pinData
            }
            dispatch(profileActions.verifyOtp({values})).then(() => {
                resetPinFirstFormRef?.current?.resetForm();
                handleClose(true);
            })
        }
    }

    return(
        <>
            <div className={"modal fade " + (isOpen ? 'show' : 'hide')} style={{display: isOpen ? 'block' : 'none'}} id="resetPin" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header row mx-0 border-0 shadow-none">
                            <div className="col-md-12 d-flex">
                                <img src={group2344Icon} alt="" style={{width: "30px"}} onClick={() => {resetPinFirstFormRef?.current?.resetForm();return handleBack(true)}} />
                                    <h4 className="modal-title text-white ms-2">Reset Pin</h4>
                            </div>
                        </div>
                        <Formik
                            initialValues={{
                                otpValue1 : "",
                                otpValue2 : "",
                                otpValue3 : "",
                                otpValue4 : ""
                            }}
                            innerRef={resetPinFirstFormRef}
                            enableReinitialize={true}
                            validationSchema={Yup.object().shape({
                                otpValue1: Yup.string().matches(/[0-9]/, "Please enter only digit"),
                                otpValue2: Yup.string().matches(/[0-9]/, "Please enter only digit"),
                                otpValue3: Yup.string().matches(/[0-9]/, "Please enter only digit"),
                                otpValue4: Yup.string().matches(/[0-9]/, "Please enter only digit")
                            })}
                            onSubmit={values => {
                                _verifyResetPin(values);
                            }}
                            >
                            {({ values, errors, touched, handleReset, handleChange}) => (
                                <Form>
                                    <div>
                                        <div className="modal-body row mx-0 py-4">
                                            <p className="text-white mb-0"><small>Enter the code sent on your email</small></p>
                                            <p className="text-white mb-0 fw-bold"><small style={{color: "yellow"}}>{authUser && authUser?.email}</small></p>
                                            {error ? <p className="text-danger">{errMessage}</p> : ''}
                                            <div className="input-container d-flex flex-row justify-content-start mt-3">
                                                <div className="col-2">
                                                    <Field type="text" innerRef={otpValue1Ref}
                                                           className="m-1 py-3 text-center form-control rounded"
                                                           maxLength="1" placeholder="-" tabIndex={1} name="otpValue1"
                                                           onInput={(event) => {
                                                               onChange(event, otpValue2Ref)
                                                           }}/>
                                                </div>
                                                &nbsp;&nbsp;
                                                <div className="col-2">
                                                    <Field type="text" innerRef={otpValue2Ref}
                                                           className="m-1 py-3 text-center form-control rounded"
                                                           maxLength="1" placeholder="-" tabIndex={2} name="otpValue2"
                                                           onInput={(event) => {
                                                               onChange(event, otpValue3Ref)
                                                           }}/>
                                                </div>
                                                &nbsp;&nbsp;
                                                <div className="col-2">
                                                    <Field type="text" innerRef={otpValue3Ref}
                                                           className="m-1 py-3 text-center form-control rounded"
                                                           maxLength="1" placeholder="-" tabIndex={3} name="otpValue3"
                                                           onInput={(event) => {
                                                               onChange(event, otpValue4Ref)
                                                           }}/>
                                                </div>
                                                &nbsp;&nbsp;
                                                <div className="col-2">
                                                    <Field type="text" innerRef={otpValue4Ref}
                                                           className="m-1 py-3 text-center form-control rounded"
                                                           maxLength="1" placeholder="-" tabIndex={4} name="otpValue4"
                                                           onInput={(event) => {
                                                               onChange(event)
                                                           }}/>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <ErrorMessage name="otpValue1" component="span"
                                                              className="invalid-feedback">
                                                    {msg => <div style={{color: 'red'}}>{msg}</div>}
                                                </ErrorMessage>
                                                <ErrorMessage name="otpValue2" component="span"
                                                              className="invalid-feedback">
                                                    {msg => <div style={{color: 'red'}}>{msg}</div>}
                                                </ErrorMessage>
                                                <ErrorMessage name="otpValue3" component="span"
                                                              className="invalid-feedback">
                                                    {msg => <div style={{color: 'red'}}>{msg}</div>}
                                                </ErrorMessage>
                                                <ErrorMessage name="otpValue4" component="span"
                                                              className="invalid-feedback">
                                                    {msg => <div style={{color: 'red'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </div>
                                        </div>
                                        <div className="modal-footer border-0 mx-0">
                                            <div className="row w-100">
                                                <div className="col-md-6 my-1">
                                                    <button type="submit" className="login-btn btn-hover color-1">
                                                        {verifyRequestLoading ?
                                                            <div
                                                                className="spinner-border spinner-border-sm text-warning"
                                                                role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div> : 'Next'}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
                <button type="button" className="btn-close rounded-circle opacity-100" data-bs-dismiss="modal"
                        onClick={() => {
                            resetPinFirstFormRef?.current?.resetForm();
                            return handleClose(false)
                        }}></button>
            </div>
        </>
    )

}
