import React from "react";
import playerName from "../../components/assets/images/icon/player-name.svg";
import flagIcon from "../../components/assets/images/icon/flag.svg";
import greenUpArrowIcon from "../../components/assets/images/icon/green-up-arrow.svg";
import ChessComponent from "./ChessGame/ChessComponent";
import GameRoomChat from "../../components/GameRoomChat";

export default function Game() {
    return(
        <>
            <div className="row games-chat">
                <div id="app">
                    <div className="chat-body">
                        <div tabIndex="-1" className="chat-content three">
                            <div className="sidebar-chat sidebar-chat-left">
                                <div className="sidebar-chat-content">
                                    <div className="row px-3 py-2">
                                        <div className="d-flex flex-row ps-3">
                                            <div className="px-3 border-secondary border-end d-flex">
                                                <img src={playerName} style={{width: "35px"}}/>
                                                    <p className="text-white fw-bold font-13 mb-0 pt-2 ps-2">James</p>
                                            </div>
                                            <div className="px-3 border-secondary border-end">
                                                <img src={flagIcon} style={{width: "30px"}} className="pt-2"/>
                                            </div>
                                            <div className="px-3 border-secondary border-end">
                                                <p className="text-secondary pt-2 fst-italic font-13"><span><img
                                                    src={greenUpArrowIcon}
                                                    style={{width: "13px"}}/></span> Ranger</p>
                                            </div>
                                            <div className="px-3 border-secondary border-end">
                                                <p className="text-white fw-bold font-13 pt-2">41231</p>
                                            </div>
                                            <div className={'px-3'}>
                                                <div
                                                      className="btn border-0 btn-hover color-1 py-2 px-3 font-13 fw-bold"
                                                      style={{backgroundColor: "#42dbcb"}}
                                                >
                                                    + Add friend
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row game-background">
                                        <ChessComponent/>
                                    </div>
                                    <div
                                        className="row px-2 m-0 py-2 border-top background-dark-grey-shade3 border-secondary"
                                        style={{position: "absolute", bottom: 0, width: "100%"}}>
                                        <div className="d-flex flex-row ps-3">
                                            <div className="px-3 border-secondary border-end d-flex">
                                                <img src={playerName} style={{width: "35px"}}/>
                                                    <p className="text-white fw-bold font-13 mb-0 pt-2 ps-2">James</p>
                                            </div>
                                            <div className="px-3 border-secondary border-end">
                                                <img src={flagIcon} style={{width: "30px"}}
                                                     className="pt-2" />
                                            </div>
                                            <div className="px-3 border-secondary border-end">
                                                <p className="text-secondary pt-2 fst-italic font-13"><span><img
                                                    src={greenUpArrowIcon}
                                                    style={{width: "13px"}}/></span> Ranger</p>
                                            </div>
                                            <div className="px-3">
                                                <p className="text-white fw-bold font-13 pt-2">41231</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           <GameRoomChat/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
