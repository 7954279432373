import React from 'react';
import group2344Icon from "../../assets/images/icon/Group 2344.svg";
import {Modal} from "react-bootstrap";
import DownloadVCoinApp from "../../DownloadVCoinApp";
import {useSelector} from "react-redux";
import _ from "lodash";

export default function CompleteKycDoMoreModal({isOpen, handleClose, handleBack}) {
    const authUser = useSelector(x => x.auth.user);
    let keyStep = 0;

    if (_.get(authUser,"kyc_1_status"))
        keyStep += 1;
    if (_.get(authUser,"kyc_2_status"))
        keyStep += 1;
    if (_.get(authUser,"kyc3_status"))
        keyStep += 1;
    return (
        <>
            <Modal
                show={isOpen}
                onHide={handleClose}
                size="md"
                centered
            >
                <Modal.Header closeButton className="row mx-0 border-0 shadow-none">
                    <div className="col-md-12 d-flex">
                        <img src={group2344Icon} alt="" className="cursor-pointer" style={{width: "30px"}} onClick={handleBack}/>
                        <h4 className="modal-title text-white ms-2">
                            Complete <span style={{color: "orange"}}>KYC {keyStep}/3</span> to do more
                        </h4>
                    </div>
                </Modal.Header>
                <div className="modal-body row mx-0 py-4">
                    <p className="text-white">
                        <small>Download <span style={{color: "yellow"}}> VirtuaCoin Wallet App</span> to complete KYC Process</small>
                    </p>
                    <DownloadVCoinApp/>
                </div>
            </Modal>
        </>
    )
}
