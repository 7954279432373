import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "../../../_store";
import _ from "lodash";

const JoiningBonusModal = () => {
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const bonusCredited = useSelector((x) => x.auth.bonusCredited);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (bonusCredited) {
            setIsOpen(true);
            const user_id = _.get(authUser,"user_id");
            if (user_id){
                dispatch(authActions.getUserDetails(user_id))
            }
        }
    }, [bonusCredited])

    return (
        <Modal
            show={isOpen}
            onHide={() => setIsOpen(false)}
            size="md"
            centered
        >
            <Modal.Header closeButton className="row mx-0 border-0 shadow-none">
                <div className="d-flex">
                    <img src="/assets/images/icon/vcoin.svg" alt=""/>
                    <h4 className="modal-title text-white ms-2">
                        Congratulations
                    </h4>
                </div>
            </Modal.Header>
            <Modal.Body className="text-white">
                <p className="mb-2">
                    Congratulations You have got V{bonusCredited} as Joining bonus
                </p>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn border-0 font-13 fw-bold py-2 btn-hover color-1 ms-2"
                    style={{backgroundColor: "#42dbcb"}}
                    onClick={() => setIsOpen(false)}
                > Okay
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default JoiningBonusModal;