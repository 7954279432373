import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';


// create slice

const name = 'notification';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const notificationActions = { ...slice.actions, ...extraActions };
export const notificationStore = slice.reducer;

// implementation

function createInitialState() {
    return {
        notifications: [],
        error: null,
        loading: false
    }
}

function createReducers() {
    return {
        resetApplication
    };

    function resetApplication(state) {
        state.notifications = [];
        state.error = null;
        state.loading = false;
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/user`;

    return {
        getNotification: getNotification(),
    };

    function getNotification() {
        return createAsyncThunk(
            `${name}/notifications`,
            async (data) => await fetchWrapper.post(`${baseUrl}/getNotification`,{appsId: data.values.appsId})
        );
    }

}

function createExtraReducers() {
    return {
        ...getNotification(),
    };
    function getNotification() {
        var { pending, fulfilled, rejected } = extraActions.getNotification;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.loading = false;
                state.notifications = action.payload.data;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }
}
