import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';


// create slice

const name = 'user';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const userActions = { ...slice.actions, ...extraActions };
export const usersReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        users: {},
        questions: [],
        resetPassword:{},
        error: null,
    }
}

function createReducers() {
    return {
        resetUsers
    };

    function resetUsers(state) {
        state.users = {};
        state.questions = [];
        state.error = null;
        state.account=null;
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/user`;
    const recoveryUrl = `${process.env.REACT_APP_API_URL}/recovery_questions`
    const AccountUrl = `${process.env.REACT_APP_API_URL}/account`

    return {
        getAll: getAll(),
        getRecoveryQuestions: getRecoveryQuestions(),
        postStatus: postStatus(),
        resetPassword:resetPassword(),
    };    

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async () => await fetchWrapper.get(baseUrl)
        );
    }

    function getRecoveryQuestions() {
        return createAsyncThunk(
            `${name}/getRecoveryQuestions`, async () => await fetchWrapper.get(recoveryUrl)
        )
    }

    function postStatus() {
        return createAsyncThunk(`${name}/change_status`, async (data) => await fetchWrapper.post(`${baseUrl}/change_status`, { ...data.values }))
    }

    function resetPassword(){
        
        return createAsyncThunk(`${name}/resetPassword`,async (data) => await fetchWrapper.post(`${baseUrl}/password/reset`,data))
    }
}

function createExtraReducers() {
    return {
        ...getAll(),
        ...getRecoveryQuestions(),
        ...getStatus(),
        ...resetPassword(),
    };

    function getAll() {
        var { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: (state) => {
                state.users = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.users = action.payload;
            },
            [rejected]: (state, action) => {
                state.users = { error: action.error };
            }
        };
    }

    function getRecoveryQuestions() {
        var { pending, fulfilled, rejected } = extraActions.getRecoveryQuestions;
        return {
            [pending]: (state) => {
                state.questions = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.questions = action.payload.data;
            },
            [rejected]: (state, action) => {
                state.questions = { error: action.error };
            }
        };
    }

    function getStatus() {
        var { pending, fulfilled, rejected } = extraActions.postStatus;
        return {
            [pending]: (state) => {
                state.users = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.users = action.payload;
            },
            [rejected]: (state, action) => {
                state.users = { error: action.error };
                state.error = action.error;
            }
        };
    }

    function resetPassword(){
        var {pending, fulfilled, rejected} = extraActions.resetPassword;
        return {
            [pending]:(state)=>{
                state.resetPassword = {loading:true}
            },
            [fulfilled]:(state,action)=>{
                state.resetPassword = action.payload
            },
            [rejected]:(state, action)=>{
                state.resetPassword = {error:action.error};
                state.error= action.error
            }
        }
    }
}
