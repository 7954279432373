import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {timeCalculate} from "../../_helpers";
import {Dropdown} from "react-bootstrap";
import {authActions, friendActions} from "../../_store";
import {toast} from "react-toastify";
import Confirmation from "../Confirmation";

const MessageContent = ({message,setSelectGameModal}) => {
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const [isOpenBlockUser, setOpenBlockUser] = React.useState(false);
    const [isOpenMuteUser, setOpenMuteUser] = React.useState(false);

    const sentFriendRequest = () => {
        const values = {
            email: _.get(message, "user.email"),
            profile: _.get(authUser, "profile_link"),
            appsId: _.get(authUser, "appInfo.id"),
        };
        dispatch(friendActions.addFriendByEmail({values})).then((response) => {
            if (response.payload?.message) {
                toast.success(response.payload.message);
            }
            if (response?.error) {
                toast.error(response.error.message);
            }
        });
    }

    const blockUser = () => {
        const values = {
            profile: _.get(authUser, "profile_link"),
            appsId: _.get(authUser, "appInfo.id"),
            block_user_id: _.get(message, "user.user_id")
        }
        dispatch(friendActions.addToBlockList(values)).then((response)=>{
            if (response.payload?.message) {
                toast.success(response.payload.message);
                setOpenBlockUser(false);
            }
            if (response?.error) {
                toast.error(response.error.message);
            }
        })
    }

    const handleMutation = () => {
        const values = {
            user_id: _.get(authUser, "user_id"),
            mute_user_id: _.get(message, "user.user_id")
        }
        dispatch(friendActions.muteUser(values)).then((response)=>{
            if (response.payload?.message) {
                dispatch(authActions.getUserDetails(values.user_id))
                toast.success(response.payload.message);
                setOpenMuteUser(false);
            }
            if (response?.error) {
                toast.error(response.error.message);
            }
        })
    }
    const isMutedUser = _.includes(_.get(authUser, "muted_user",[]),_.get(message, "user.user_id",""));
    if (isMutedUser){
        return null;
    }
    return (
        <>
            {(_.get(message, "user.user_id") === _.get(authUser, "user_id")) ? (
                <div className="player-out-msg" key={message.id}>
                    <div className="chat-msg-color chatmsg-width message-out">
                        <div className="msg-out-time" role="button">
                            <span>{message.createdAt ? timeCalculate(message.createdAt.toDate()) : ""}</span>
                        </div>
                        <div className="d-flex align-items-start gap-2">
                            <div>
                                <div className="out-msgs">
                                    <div>
                                        <div className="game-player-chat-msg">
                                            <span className="selectable-text">{message.message}</span>
                                        </div>
                                    </div>
                                </div>
                                <span className="msg-out-time font-12 float-end">
                                    {_.get(message, "user.first_name", "")} {_.get(message, "user.last_name", "")}
                                </span>
                            </div>
                            <img
                                src={_.get(message, "user.profile_picture", "")}
                                alt="Profile Picture"
                                className="img-fluid mt-2"
                                style={{
                                    width: "25px",
                                    borderRadius: "10px"
                                }}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="player-out-msg">
                    <div className="d-flex gap-2 align-items-start">
                        <Dropdown>
                            <Dropdown.Toggle variant="success">
                                <img
                                    src={_.get(message, "user.profile_picture", "")}
                                    alt="Profile Picture"
                                    className="img-fluid mt-3"
                                    style={{
                                        width: "25px",
                                        borderRadius: "10px"
                                    }}
                                />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item>Profile</Dropdown.Item>
                                <Dropdown.Item onClick={sentFriendRequest}>Add Friend</Dropdown.Item>
                                <Dropdown.Item onClick={()=>setSelectGameModal(true)}>Invite to Play</Dropdown.Item>
                                <Dropdown.Item onClick={()=>setOpenMuteUser(true)}>
                                    Mute
                                </Dropdown.Item>
                                <Dropdown.Item onClick={()=>setOpenBlockUser(true)}>Block</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className="d-flex flex-column">
                            <div className="msg-out-time">
                                <span className="_1ORuP"></span>
                                <span>{message.createdAt ? timeCalculate(message.createdAt.toDate()) : ""}</span>
                            </div>
                            <div>
                                <div className="chat-msg-color chatmsg-width message-in">
                                    <div className="out-msgs">
                                        <div>
                                            <div className="game-player-chat-msg">
                                                <span className="selectable-text">{message.message}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="msg-out-time font-12">
                                {_.get(message, "user.first_name", "")} {_.get(message, "user.last_name", "")}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Confirmation
                headerIcon="/assets/images/icon/3994435_ban_banned_block_disabled_stop_icon.svg"
                title="Block User"
                isOpen={isOpenBlockUser}
                handleClose={()=>setOpenBlockUser(false)}
                onConfirm={blockUser}
                modalBody={
                    <p>
                        Are you sure you want to Block
                        "{_.get(message, "user.first_name", "")} {_.get(message, "user.last_name", "")}"?
                    </p>
                }
            />
            <Confirmation
                headerIcon="/assets/images/icon/3994435_ban_banned_block_disabled_stop_icon.svg"
                title="Mute User"
                isOpen={isOpenMuteUser}
                handleClose={()=>setOpenMuteUser(false)}
                onConfirm={handleMutation}
                modalBody={<p> Are you sure you want to Mute this User? </p>}
            />
        </>
    );
};

export default MessageContent;