import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {applicationActions} from "../../../_store";
import gameLogoIcon from "../../assets/images/icon/Icon ionic-logo-game-controller-b.svg";
import startIcon from "../../assets/images/icon/star.svg";
import  "../../assets/css/selectGameModel.css"
import {Modal} from "react-bootstrap";

export default function SelectGameModal({isOpen, handleClose}) {
    const [selectedGame, setSelectedGame] = useState()

    const dispatch = useDispatch();

    const applicationListResponse = useSelector((x) => x.application.applicationItem);

    const handleSelectGame = (game) => {
        setSelectedGame(game)
    }

    useEffect(()=>{
        dispatch(applicationActions.getApplicationList());
    },[])


    return (
        <Modal
            show={isOpen}
            onHide={handleClose}
            size="lg"
            centered
        >
            <Modal.Header closeButton className="row mx-0 border-0 shadow-none">
                <div className="col-md-12 d-flex">
                    <img src={gameLogoIcon} alt="" style={{width: "30px"}}/>
                    <h4 className="modal-title text-white ms-2">Select Game To Send Request</h4>
                </div>
            </Modal.Header>
            <form className="select_game_modal">
                <div className="modal-body row mx-0">
                    {(applicationListResponse || []).map((e, i) => {
                        return e.app_icon && (
                            <div className="col-md-6 pe-3" key={i}>
                                <div className="active select-game" onClick={() => handleSelectGame(i)}>
                                    <div
                                        className={`row d-flex align-items-center justify-content-center my-2  ${selectedGame === i ? 'active' : 'noraml'}`}>
                                        <div className="col-md-3 pe-0">
                                            <img src={"https://virtuagrid.com/" + e.app_icon}
                                                 alt="Glamdiva: Fashion Stylist" style={{width: "67px",borderRadius:18}}/>
                                        </div>
                                        <div className="col-md-9 ps-0 py-1">
                                            <p className="font-13 fw-bold text-white mb-0"> {e.app_name}</p>
                                            <p className="text-muted font-13 mb-0">Card</p>
                                            <p className="text-white font-10">4.7 <img src={startIcon} alt="rating" style={{width: "10px"}}/></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="modal-footer border-0 mx-0">
                    <a className="login-btn btn-hover color-1" href="#">Send Game Request</a>
                </div>
            </form>
        </Modal>
    );

}
