import React, {useEffect, useRef} from "react";
import Slider from "react-slick";
import TabMenu from "../../components/layout/tabContent/tabMenuItem";
import { Link } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {applicationActions, gameActions} from "../../_store";
import _ from "lodash";

function Favourite() {
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const applicationListResponse = useSelector(
        (x) => x.games.favApplicationItem
    );

    useEffect(()=>{
        const values = {
            user_id: _.get(authUser, "user_id")
        }
        dispatch(gameActions.getFavApplicationList(values));
    },[])

    //creating the ref
    const customSlider = useRef();
    const secondSlider = useRef();
    const thirdSlider = useRef();

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        variableWidth: true,
    };

    const secondSliderSettings = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        variableWidth: true,
    };

    const thirdSliderSettings = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        variableWidth: true,
    };

    const gotoNext = (ref) => {
        ref.current.slickNext();
    };

    const gotoPrev = (ref) => {
        ref.current.slickPrev();
    };

    return (
        <>
            <TabMenu />
            <div className="recommanded">
                <div className="recommended_slider swiper-container pt-4 px-4">
                    <div className="d-flex justify-content-between">
                        <div className="recommanded-left">
                            <h2 className="text-white mb-0">Recommended for you</h2>
                            <p className="text-white">Games you should play</p>
                        </div>
                        <div className="recommanded-right">
                            <div
                                className="swiper-button-prev"
                                onClick={() => gotoPrev(customSlider)}
                            >
                                <img src="assets/images/icon/left-arrow.svg" alt="left arrow" />
                            </div>
                            <div
                                className="swiper-button-next"
                                onClick={() => gotoNext(customSlider)}
                            >
                                <img
                                    src="assets/images/icon/right-arrow.svg"
                                    alt="right arrow"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="slider-section"></div>
                    <div className="swiper-wrapper">
                        <Slider {...settings} ref={customSlider}>
                            {(applicationListResponse || []).map((e, i) => {
                                return (
                                    <div
                                        className="swiper-slide"
                                        key={i + "1"}
                                        style={{ width: 120 }}
                                    >
                                        <Link to={`/game_info/${e.id}`}>
                                            <img
                                                src={e.app_icon}
                                                alt="Glamdiva: Fashion Stylist"
                                            />
                                            <div>
                                                <p className="text-white mb-0 mt-2">{e.app_name}</p>
                                                <div className="rating text-white">
                                                    {e.rating}
                                                    <img
                                                        src="/assets/images/icon/rating.svg"
                                                        alt="rating"
                                                    />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                </div>
            </div>

            <div className="row px-4 py-2">
                <section className="ps-0 pe-0 float-end slider_sec">
                    <div className="latest_games">
                        <div className="latest-games swiper-container pt-4 px-0">
                            <div className="d-flex justify-content-between">
                                <div className="new-update-left">
                                    <h2 className="text-white mb-0">Latest Games</h2>
                                    <p className="text-white">Play the latest innovative games</p>
                                </div>
                                <div className="new-update-right">
                                    <div
                                        className="swiper-button-prev"
                                        id="new-update-slider-button"
                                        onClick={() => gotoPrev(secondSlider)}
                                    >
                                        <img
                                            src="assets/images/icon/left-arrow.svg"
                                            alt="left arrow"
                                        />
                                    </div>
                                    <div
                                        className="swiper-button-next"
                                        id="new-update-slider-button"
                                        onClick={() => gotoNext(secondSlider)}
                                    >
                                        <img
                                            src="assets/images/icon/right-arrow.svg"
                                            alt="right arrow"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-wrapper py-2">
                                <Slider {...secondSliderSettings} ref={secondSlider}>
                                    {(_.filter(applicationListResponse,{is_latest:1}) || []).map((e, i) => {
                                        return e.app_icon && (
                                            <div
                                                className="swiper-slide"
                                                key={i + "2"}
                                                style={{ width: 120 }}
                                            >
                                                <Link to={`/game_info/${e.id}`}>
                                                    <img
                                                        src={e.app_icon}
                                                        alt="Glamdiva: Fashion Stylist"
                                                    />
                                                    <div>
                                                        <p className="text-white mb-0 mt-2">
                                                            {e.app_name}
                                                        </p>
                                                        <div className="rating text-white">
                                                            {e.rating}
                                                            <img
                                                                src="/assets/images/icon/rating.svg"
                                                                alt="rating"
                                                            />
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        );
                                    })}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="row px-4 py-0">
                <section className="ps-0 pe-0 float-end slider_sec">
                    <div className="horizontal-divider border-top opacity-50"></div>
                    <div className="new_updated">
                        <div className="new_updated_slider swiper-container pt-4 px-0">
                            <div className="d-flex justify-content-between">
                                <div className="new-update-left">
                                    <h2 className="text-white mb-0">New & updated games</h2>
                                    <p className="text-white">Selected games of the week</p>
                                </div>
                                <div className="new-update-right">
                                    <div
                                        className="swiper-button-prev"
                                        id="new-update-slider-button"
                                        onClick={() => gotoPrev(thirdSlider)}
                                    >
                                        <img
                                            src="/assets/images/icon/left-arrow.svg"
                                            alt="left arrow"
                                        />
                                    </div>
                                    <div
                                        className="swiper-button-next"
                                        id="new-update-slider-button"
                                        onClick={() => gotoNext(thirdSlider)}
                                    >
                                        <img
                                            src="/assets/images/icon/right-arrow.svg"
                                            alt="right arrow"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-wrapper  align-items-center pt-2 pb-5">
                                <Slider {...thirdSliderSettings} ref={thirdSlider}>
                                    {(applicationListResponse || []).map((e, i) => {
                                        return e.app_icon && (
                                            <div
                                                className="swiper-slide"
                                                key={i + "3"}
                                                style={{ width: 300 }}
                                            >
                                                <Link to={`/game_info/${e.id}`}>
                                                    <img
                                                        src={e.app_icon}
                                                        className="w-auto"
                                                        alt="Card"
                                                        style={{height:256}}
                                                    />
                                                    <div className="latest_card pt-4 align-items-center row">
                                                        <img
                                                            src={e.app_icon}
                                                            className="col-md-5"
                                                            alt="Legends of Runeterra"
                                                        />
                                                        <div className="col-md-7 px-0">
                                                            <p className="text-white mb-0 mt-0 opacity-100 h-auto">
                                                                {e.app_name}
                                                            </p>
                                                            <p className="text-white mb-0 mt-0  h-auto card_content">
                                                                Card
                                                            </p>
                                                            <div className="rating text-white">
                                                                {e.rating}{" "}
                                                                <img
                                                                    src="/assets/images/icon/rating.svg"
                                                                    alt="rating"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        );
                                    })}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/*<Slider key={1} title="Recommended for you" subTitle="Games you should play" classNameMain="recommanded" classNameSub="recommended_slider swiper-container pt-4 px-4" slidesPerView="11" navigation />*/}
            {/*<Slider key={2} title="Latest Games" subTitle="Play the latest innovative games" classNameMain="latest_games" classNameSub="latest-games swiper-container pt-4 px-0" slidesPerView="11" />*/}
            {/*<Slider key={3} title="New & updated games" subTitle="Selected games of the week" classNameMain="new_updated" classNameSub="new_updated_slider swiper-container pt-4 px-0" slidesPerView="4" newupdate="true" navigation={true}/>*/}
        </>
    );
}

export default Favourite;
