import React from 'react';
import starIcon from "../../../components/assets/images/icon/star.svg";
import playerName from "../../../components/assets/images/icon/player-name.svg";
import flagIcon from "../../../components/assets/images/icon/flag.svg";
import playerName1 from "../../../components/assets/images/icon/player-name1.svg";
import flagIcon1 from "../../../components/assets/images/icon/flag1.svg";
import playerName2 from "../../../components/assets/images/icon/player-name2.svg";
import flagIcon2 from "../../../components/assets/images/icon/flag2.svg";
import infoCircleIcon from "../../../components/assets/images/icon/icon-info-circle.svg";
import greenUpArrowIcon from "../../../components/assets/images/icon/green-up-arrow.svg";
import virtuaCoinIcon from "../../../components/assets/images/icon/virtua-coin.svg";
import heartIcon from "../../assets/images/icon/heeart-icon.svg"
import {Link, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import GameChatSection from "../GameChatSection";
import "../../assets/css/game-info.css";
import {addOrdinalIndicator, generate_uuid} from "../../../_helpers";
import _ from "lodash";
import {doc,setDoc} from "firebase/firestore";
import {db} from "../../../firebase-config";
import {gameActions} from "../../../_store";
import {toast} from "react-toastify";

const GameInfoTab = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {id} = useParams();
    const applicationInfo = useSelector( (x) => x.application.applicationInfo);
    const authUser = useSelector(x => x.auth.user);

    const createNewGameRoom = async () => {
        const uuid = generate_uuid();
        const data = { userId: authUser.user_id , position:"rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1" }
        await setDoc(doc(db, "chess", uuid), data, { merge: true })
        navigate(`/game/${id}/${uuid}`);
    }

    const addToFavGame = () => {
        const values = {
            user_id: _.get(authUser, "user_id"),
            application_id: _.get(applicationInfo,'id')
        }
        dispatch(gameActions.addToFavGame(values)).then((response)=>{
            if (response.payload?.message) {
                toast.success(response.payload.message);
            }
            if (response?.error) {
                toast.error(response.error.message);
            }
        })
    }

    return (
        <div className="row px-3">
            <div className="col-lg-8 col-md-8">
                <div className="row">
                    <div className="col-lg-2 col-md-2">
                        {_.get(applicationInfo,"app_icon") && (
                            <img
                                src={"https://virtuagrid.com/" + _.get(applicationInfo,"app_icon")}
                                alt="applicationInfo"
                                className="img-fluid"
                                width="127"
                            />
                        )}
                    </div>
                    <div className="col-lg-10 col-md-10 ps-4 pt-1">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 d-flex">
                                <h4 className="font-oval-single font-34 text-white">
                                    {_.get(applicationInfo, "app_name", "")}
                                </h4>
                                <div className={'px-3'}>
                                    <button
                                        disabled={!authUser}
                                        className="btn border-0 btn-hover color-1 py-2 px-3 font-13 fw-bold"
                                        onClick={addToFavGame}
                                    >
                                        {/*+ Add to Fav*/}
                                        334
                                        <img src={heartIcon} alt="heartIcon" className="ms-1" style={{width:20}}/>
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="row justify-content-end me-2">
                                    <div className="col-lg-6 col-md-6 px-0">
                                        <p className="text-white font-13 text-end mb-0">
                                            {_.get(applicationInfo, "rating", "")}
                                            {" "}
                                            <img
                                                src={starIcon}
                                                alt="starIcon"
                                                className="ms-1"
                                                style={{width: "12px"}}
                                            />
                                        </p>
                                        <p className="text-muted font-13 text-end">
                                            452 Reviews
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <p className="font-13 text-white">
                                {_.get(applicationInfo,"description","")}
                                {/*<span className="yellow-heading">Read More</span>*/}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-lg-6 col-md-6 background-light-grey rounded-2">
                        <div className="row px-4 pt-3">
                            <p className="font-22 font-oval-single color-green p-0 mb-0">
                                Current Toppers
                            </p>
                        </div>
                        <div className="row px-4 pt-2">
                            <table className="table text-white font-13 border-secondary">
                                <tbody>
                                <tr>
                                    <td className="fw-bold">1.</td>
                                    <td>
                                        <img src={playerName} style={{width: "25px"}}/>
                                    </td>
                                    <td className="fw-bold">John</td>
                                    <td>
                                        <img src={flagIcon} style={{width: "25px"}}/>
                                    </td>
                                    <td>3515 pts.</td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">2.</td>
                                    <td>
                                        <img
                                            src={playerName1}
                                            style={{width: "25px"}}
                                        />
                                    </td>
                                    <td className="fw-bold">Bruce</td>
                                    <td>
                                        <img src={flagIcon1} style={{width: "25px"}}/>
                                    </td>
                                    <td>3145 pts.</td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">3.</td>
                                    <td>
                                        <img
                                            src={playerName2}
                                            style={{width: "25px"}}
                                        />
                                    </td>
                                    <td className="fw-bold">Adam</td>
                                    <td>
                                        <img src={flagIcon2} style={{width: "25px"}}/>
                                    </td>
                                    <td>2954 pts.</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {authUser && (
                        <div className="col-lg-6 col-md-6 px-4">
                            <div className="row">
                                <div className="col-lg-8 col-md-8">
                                    <p className="font-16 yellow-heading">
                                        Weekly Reward
                                        <span className="font-oswald ms-2 fw-bold text-white font-16">
                                        <img
                                            src={virtuaCoinIcon}
                                            alt="virtuaCoinIcon"
                                            style={{width: "25px"}}
                                        />{" "}
                                            {_.get(authUser, "weekly_rewards", "0")}
                                        </span>
                                    </p>
                                </div>
                            <div className="col-lg-4 col-md-4 text-end">
                                <span>
                                    <img src={infoCircleIcon} alt="infoCircleIcon" style={{width: "20px"}}/>
                                </span>
                            </div>
                            </div>
                             <div className="row mt-2 px-3">
                                 <div className="col-lg-12 col-md-12 border border-secondary rounded-3 py-2">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 border-secondary border-end text-center">
                                            <img src={_.get(authUser, "profile_picture", "")} alt="profile_picture" style={{width: "100px"}}/>
                                            <p className="font-13 mb-0 text-white fw-bold mt-2">
                                                {_.get(authUser, "first_name", "")}
                                            </p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 text-center">
                                            <p className="font-oval-single mb-0 font-60 text-white">
                                                {addOrdinalIndicator(_.get(applicationInfo,"rank_level",0))}
                                            </p>
                                            <p className="font-20 text-white">Position</p>
                                        </div>
                                    </div>
                                </div>
                             </div>
                        </div>
                    )}
                </div>
                <div className="row mt-3">
                    <div className="col-12 ps-1 pe-4 game-tabs">
                        <ul className="nav nav-tabs" id="game-tab" role="tablist">
                            <li>
                                <p className="font-22 font-oval-single color-green p-0 mb-0">
                                    Current Toppers
                                </p>
                            </li>
                            <li
                                className="nav-item ms-auto"
                                role="presentation"
                            >
                                <button
                                    className="nav-link active"
                                    id="quick-match-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#quick-match"
                                    type="button"
                                    role="tab"
                                    aria-controls="quick-match"
                                    aria-selected="false"
                                >
                                    Quick Match
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="tournament-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#tournament"
                                    type="button"
                                    role="tab"
                                    aria-controls="tournament"
                                    aria-selected="false"
                                >
                                    Tournament
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContentGames">
                        <div
                                className="tab-pane fade show active px-2"
                                id="quick-match"
                                role="tabpanel"
                                aria-labelledby="quick-match-tab"
                            >
                                <div className="row quick-matches-card ">
                                    <div className="col-lg-3 col-md-3">
                                        <div className="card rounded-4 border-secondary">
                                            <div className="card-body text-center py-4">
                                                <button
                                                    className="btn font-13 fw-bold border-0 py-1 card-btn"
                                                    style={{
                                                        position: "absolute",
                                                        backgroundColor: "#FCD32C",
                                                        top: "-7%",
                                                        right: "30%",
                                                    }}
                                                >
                                                    <img
                                                        src={virtuaCoinIcon}
                                                        style={{width: "25px"}}
                                                    />
                                                    0{" "}
                                                    <span className="text-dark font-8">
                                    (FREE)
                                  </span>
                                                </button>
                                                <div className="row border-bottom border-secondary">
                                                    <p className="text-white font-oval-single font-60 mb-0">
                                                        30
                                                    </p>
                                                    <p className="fw-bold text-white font-16 mb-2">
                                                        MIN
                                                    </p>
                                                </div>
                                                <div className="row pt-3 pb-1">
                                                    <p className="text-white font-13 fw-bold mb-0">
                                                        NO PRIZE
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            className="pt-2 pb-1 mx-auto popular-button"
                                            style={{display: "none"}}
                                        >
                                            POPULAR
                                        </button>
                                        <div className="text-center mt-2 ps-0">
                                            <button
                                                onClick={createNewGameRoom}
                                              className="btn border-0 btn-hover color-1 py-2 px-3 font-13 fw-bold"
                                              disabled={!authUser}
                                            >
                                                Play
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="card rounded-4 border-secondary">
                                            <div className="card-body text-center py-4">
                                                <button
                                                    className="btn font-13 fw-bold border-0 py-1 card-btn"
                                                    style={{
                                                        position: "absolute",
                                                        backgroundColor: "#FCD32C",
                                                        top: "-7%",
                                                        right: "36%",
                                                    }}
                                                >
                                                    <img
                                                        src={virtuaCoinIcon}
                                                        style={{width: "25px"}}
                                                    />
                                                    20
                                                </button>
                                                <div className="row border-bottom border-secondary">
                                                    <p className="text-white font-oval-single font-60 mb-0">
                                                        30
                                                    </p>
                                                    <p className="fw-bold text-white font-16 mb-2">
                                                        MIN
                                                    </p>
                                                </div>
                                                <div className="row pt-3 pb-0">
                                                    <p className="text-white font-13 fw-bold mb-0">
                                                        PRIZE{" "}
                                                        <span className="yellow-heading">
                                      <img
                                          src={virtuaCoinIcon}
                                          style={{width: "25px"}}
                                      />{" "}
                                                            17{" "}
                                                            <img
                                                                src={greenUpArrowIcon}
                                                                className="ms-2"
                                                                style={{
                                                                    width: "15px",
                                                                    marginTop: "-5px",
                                                                }}
                                                            />
                                    </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            className="pt-2 pb-1 mx-auto popular-button"
                                            style={{display: "none"}}
                                        >
                                            POPULAR
                                        </button>
                                        <div className="text-center mt-2 ps-0">
                                            <button
                                                onClick={createNewGameRoom}
                                                className="btn border-0 btn-hover color-1 py-2 px-3 font-13 fw-bold"
                                                disabled={!authUser}
                                            >
                                                Play
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="card rounded-4 border-secondary">
                                            <div className="card-body text-center py-4">
                                                <button
                                                    className="btn font-13 fw-bold border-0 py-1 card-btn"
                                                    style={{
                                                        position: "absolute",
                                                        backgroundColor: "#FCD32C",
                                                        top: "-7%",
                                                        right: "36%",
                                                    }}
                                                >
                                                    <img
                                                        src={virtuaCoinIcon}
                                                        style={{width: "25px"}}
                                                    />
                                                    10
                                                </button>
                                                <div className="row border-bottom border-secondary">
                                                    <p className="text-white font-oval-single font-60 mb-0">
                                                        15
                                                    </p>
                                                    <p className="fw-bold text-white font-16 mb-2">
                                                        MIN
                                                    </p>
                                                </div>
                                                <div className="row pt-3 pb-0">
                                                    <p className="text-white font-13 fw-bold mb-0">
                                                        PRIZE{" "}
                                                        <span className="yellow-heading">
                                      <img
                                          src={virtuaCoinIcon}
                                          style={{width: "25px"}}
                                      />{" "}
                                                            17{" "}
                                                            <img
                                                                src={greenUpArrowIcon}
                                                                className="ms-2"
                                                                style={{
                                                                    width: "15px",
                                                                    marginTop: "-5px",
                                                                }}
                                                            />
                                    </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            className="pt-2 pb-1 mx-auto popular-button"
                                            style={{display: "none"}}
                                        >
                                            POPULAR
                                        </button>
                                        <div className="text-center mt-2 ps-0">
                                            <button
                                                onClick={createNewGameRoom}
                                                className="btn border-0 btn-hover color-1 py-2 px-3 font-13 fw-bold"
                                                disabled={!authUser}
                                            >
                                                Play
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="card rounded-4 border-secondary">
                                            <div className="card-body text-center py-4">
                                                <button
                                                    className="btn font-13 fw-bold border-0 py-1 card-btn"
                                                    style={{
                                                        position: "absolute",
                                                        backgroundColor: "#FCD32C",
                                                        top: "-7%",
                                                        right: "37%",
                                                    }}
                                                >
                                                    <img
                                                        src={virtuaCoinIcon}
                                                        style={{width: "25px"}}
                                                    />
                                                    5
                                                </button>
                                                <div className="row border-bottom border-secondary">
                                                    <p className="text-white font-oval-single font-60 mb-0">
                                                        5
                                                    </p>
                                                    <p className="fw-bold text-white font-16 mb-2">
                                                        MIN
                                                    </p>
                                                </div>
                                                <div className="row pt-3 pb-0">
                                                    <p className="text-white font-13 fw-bold mb-0">
                                                        PRIZE{" "}
                                                        <span className="yellow-heading">
                                      <img
                                          src={virtuaCoinIcon}
                                          style={{width: "25px"}}
                                      />{" "}
                                                            7{" "}
                                                            <img
                                                                src={greenUpArrowIcon}
                                                                className="ms-2"
                                                                style={{
                                                                    width: "15px",
                                                                    marginTop: "-5px",
                                                                }}
                                                            />
                                    </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            className="pt-2 pb-1 mx-auto popular-button"
                                            style={{display: "none"}}
                                        >
                                            POPULAR
                                        </button>
                                        <div className="text-center mt-2 ps-0">
                                            <button
                                                onClick={createNewGameRoom}
                                                className="btn border-0 btn-hover color-1 py-2 px-3 font-13 fw-bold"
                                                disabled={!authUser}
                                            >
                                                Play
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade px-2"
                                id="tournament"
                                role="tabpanel"
                                aria-labelledby="tournament-tab"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
            <GameChatSection/>
        </div>
    )
};

export default GameInfoTab;