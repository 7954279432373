import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';


// create slice

const name = 'games';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const gameActions = { ...slice.actions, ...extraActions };
export const gameReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        gameItem: [],
        gameCategory: [],
        favApplicationItem: [],
        error: null,
        loading: false
    }
}

function createReducers() {
    return {
        resetGame
    };

    function resetGame(state) {
        state.gameItem = [];
        state.gameCategory = [];
        state.favApplicationItem = [];
        state.error = null;
        state.loading = false;
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/game_store`;

    const getGameList = createAsyncThunk(
        `${name}/gameStoreDetails`,
        async () => await fetchWrapper.post(`${baseUrl}/details`, {game_store_id: 1})
    );

    const getGameCategories = createAsyncThunk(
        `${name}/getGameCategoryDetails`,
        async () => await fetchWrapper.get(`${process.env.REACT_APP_API_URL}/games/categories`)
    );

    const addToFavGame = createAsyncThunk(
        `${name}/addToFavGame`,
        async (data) => await fetchWrapper.post(`${process.env.REACT_APP_API_URL}/games/favourite/add`, data)
    );

    const getFavApplicationList = createAsyncThunk(
        `${name}/getFavApplicationInfo`,
        async (data) => await fetchWrapper.post(`${process.env.REACT_APP_API_URL}/games/favourite/get`, data)
    );

    return {
        getGameList,
        getGameCategories,
        addToFavGame,
        getFavApplicationList
    };
}

function createExtraReducers() {
    return {
        ...getGameList(),
        ...getGameCategories(),
        ...addToFavGame(),
        ...getFavApplicationList(),
    };

    function getGameList() {
        var { pending, fulfilled, rejected } = extraActions.getGameList;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.gameItem = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state) => {
                state.loading = false;
            }
        };
    }
    function getGameCategories() {
        var { pending, fulfilled, rejected } = extraActions.getGameCategories;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.gameCategory = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state) => {
                state.loading = false;
            }
        };
    }

    function addToFavGame() {
        var { pending, fulfilled, rejected } = extraActions.addToFavGame;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }

    function getFavApplicationList() {
        var { pending, fulfilled, rejected } = extraActions.getFavApplicationList;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.favApplicationItem = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }
}
