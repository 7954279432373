import React, {Component} from "react";
import PropTypes from "prop-types";
import Chess from "chess.js";
import Chessboard from "chessboardjsx";
import {db} from "../../../firebase-config";
import {onSnapshot, doc, setDoc} from "firebase/firestore";
import {useSelector} from "react-redux";
import _ from "lodash";
import ChessWinModel from "../../../components/SuccessModel/chessWinModel";
import SuccessModel from "../../../components/SuccessModel";
import ChessDrawModel from "../../../components/SuccessModel/chessDrawModel";

class HumanVsHuman extends Component {
    static propTypes = {children: PropTypes.func};

    state = {
        fen: "start",
        dropSquareStyle: {},
        squareStyles: {},
        pieceSquare: "",
        square: "",
        history: [],
        pieceColor: "w",
        gameLossModel: false,
        gameWinModel:false,
        gameDrawModel:false,
    };

    componentDidMount() {
        const id = window.location.pathname.split("/").pop();
        const roomInfo = doc(db, "chess", id);
        onSnapshot(roomInfo , (doc) => {
            this.game = new Chess(doc.data().position);

            if (this.game.in_checkmate()) {
                if (this.game.turn() === "w") {
                    if(this.state.pieceColor === "w") {
                        this.setState(prevState => ({
                            gameLossModel: true
                        }));
                    } else {
                        this.setState(prevState => ({
                            gameWinModel: true
                        }));
                    }
                } else if (this.game.turn() === "b") {
                    if(this.state.pieceColor === "b") {
                        this.setState(prevState => ({
                            gameLossModel: true
                        }));
                    } else {
                        this.setState(prevState => ({
                            gameWinModel: true
                        }));
                    }

                }
            } else if (this.game.in_draw()) {
                this.setState(prevState => ({
                    gameDrawModel: true
                }));
            }

            if (doc.exists) {
                this.setState({
                    fen:doc.data().position,
                    history: this.game.history({verbose: true}),
                })
                if (_.get(this.props.authUser,"user_id") === doc.data().userId) {
                    this.togglePieceColor("w");
                } else {
                    this.togglePieceColor("b");
                }
            }
        });
    }

    togglePieceColor = (pieceColor = "") => {
        this.setState(prevState => ({
            pieceColor: pieceColor,
        }), () => {

            this.game.turn(pieceColor);
            const newFen = this.game.fen();
            this.setState({
                fen: newFen,
            });
        });
    };

    isMoveAllowed = (sourceSquare) => {
        const piece = this.game.get(sourceSquare);
        if (!piece) return false; // No piece on the source square
        const pieceColor = piece.color; // 'w' or 'b'
        return pieceColor === this.state.pieceColor;
    };

    removeHighlightSquare = () => {
        this.setState(({pieceSquare, history}) => ({
            squareStyles: squareStyling({pieceSquare, history})
        }));
    };

    // show possible moves
    highlightSquare = (sourceSquare, squaresToHighlight) => {
        if (!this.isMoveAllowed(sourceSquare)) return;
        const highlightStyles = [sourceSquare, ...squaresToHighlight].reduce(
            (a, c) => {
                return {
                    ...a,
                    ...{
                        [c]: {
                            background: "radial-gradient(circle, rgba(42,42,42,0.5) 27%, transparent 30%)",
                            borderRadius: "50%"
                        }
                    },
                    ...squareStyling({
                        history: this.state.history,
                        pieceSquare: this.state.pieceSquare
                    })
                };
            },
            {}
        );

        this.setState(({squareStyles}) => ({
            squareStyles: {...squareStyles, ...highlightStyles}
        }));
    };

    onDrop = async ({sourceSquare, targetSquare}) => {
        if (!this.isMoveAllowed(sourceSquare)) return;
        let move = this.game.move({
            from: sourceSquare,
            to: targetSquare,
            promotion: "q"
        });

        // illegal move
        if (move === null) return;
        const id = window.location.pathname.split("/").pop();
        await setDoc(doc(db, "chess", id), {position:this.game.fen()}, { merge: true })
        this.setState(({history, pieceSquare}) => ({
            fen: this.game.fen(),
            history: this.game.history({verbose: true}),
            squareStyles: squareStyling({pieceSquare, history})
        }));

    };

    closewinModel = () => {
        this.setState({gameWinModel: false})
    }

    closeLossModel = () => {
        this.setState({gameLossModel: false})
    }

    closeDrawModel = () => {
        this.setState({gameDrawModel: false})
    }

    onMouseOverSquare = square => {
        // get list of possible moves for this square
        let moves = this.game.moves({
            square: square,
            verbose: true
        });

        // exit if there are no moves available for this square
        if (moves.length === 0) return;

        let squaresToHighlight = [];
        for (var i = 0; i < moves.length; i++) {
            squaresToHighlight.push(moves[i].to);
        }

        this.highlightSquare(square, squaresToHighlight);
    };

    onMouseOutSquare = square => this.removeHighlightSquare(square);

    // central squares get diff dropSquareStyles
    onDragOverSquare = square => {
        this.setState({
            dropSquareStyle:
                square === "e4" || square === "d4" || square === "e5" || square === "d5"
                    ? {backgroundColor: "rgb(135 101 73)"}
                    : {boxShadow: "inset 0 0 1px 4px rgb(135 101 73)"}
        });
    };

    onSquareClick = square => {
        if (this.state.pieceSquare && !this.isMoveAllowed(this.state.pieceSquare)) return;
        this.setState(({history}) => ({
            squareStyles: squareStyling({pieceSquare: square, history}),
            pieceSquare: square
        }));

        let move = this.game.move({
            from: this.state.pieceSquare,
            to: square,
            promotion: "q" // always promote to a queen for example simplicity
        });

        // illegal move
        if (move === null) return;

        this.setState({
            fen: this.game.fen(),
            history: this.game.history({verbose: true}),
            pieceSquare: ""
        });
    };

    onSquareRightClick = square =>this.setState({
            squareStyles: {[square]: {backgroundColor: "deepPink"}}
        });

    handleRematch = async() => {
        this.setState({gameLossModel: false,gameWinModel: false,gameDrawModel: false})
        const id = window.location.pathname.split("/").pop();
        await setDoc(doc(db, "chess", id), {position:"rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1"}, { merge: true })
    }

    render() {
        const {fen, dropSquareStyle, squareStyles,gameLossModel,gameWinModel,gameDrawModel} = this.state;

        return this.props.children({
            squareStyles,
            position: fen,
            onMouseOverSquare: this.onMouseOverSquare,
            onMouseOutSquare: this.onMouseOutSquare,
            onDrop: this.onDrop,
            dropSquareStyle,
            onDragOverSquare: this.onDragOverSquare,
            onSquareClick: this.onSquareClick,
            onSquareRightClick: this.onSquareRightClick,
            togglePieceColor: this.togglePieceColor,
            pieceColor: this.state.pieceColor,
            closeWinModel : this.closewinModel,
            closeLossModel : this.closeLossModel,
            closeDrawModel : this.closeDrawModel,
            handleRematch : this.handleRematch,
            gameLossModel,
            gameWinModel,
            gameDrawModel
        });
    }
}

export default function WithMoveValidation() {
    const authUser = useSelector(x => x.auth.user);
    return (
        <div>
            <HumanVsHuman authUser={authUser}>
                {({
                      position,
                      onDrop,
                      onMouseOverSquare,
                      onMouseOutSquare,
                      squareStyles,
                      dropSquareStyle,
                      onDragOverSquare,
                      onSquareClick,
                      onSquareRightClick,
                      togglePieceColor,
                      pieceColor,
                      closeWinModel,
                      closeLossModel,
                      gameWinModel,
                      gameLossModel,
                      closeDrawModel,
                      gameDrawModel,
                      handleRematch
                  }) => {
                    return (
                        <>
                            <Chessboard
                                id="humanVsHuman"
                                width={600}
                                position={position}
                                onDrop={onDrop}
                                onMouseOverSquare={onMouseOverSquare}
                                onMouseOutSquare={onMouseOutSquare}
                                orientation={pieceColor === "w" ? "white" : "black"}
                                boardStyle={{
                                    borderRadius: "5px",
                                    boxShadow: `0 5px 15px rgba(0, 0, 0, 0.5)`
                                }}
                                squareStyles={squareStyles}
                                dropSquareStyle={dropSquareStyle}
                                onDragOverSquare={onDragOverSquare}
                                onSquareClick={onSquareClick}
                                onSquareRightClick={onSquareRightClick}
                            />
                            <SuccessModel
                                isOpen={gameLossModel}
                                handleClose={closeLossModel}
                                blackMoves={'24'}
                                modalBody={"hii"}
                                title={"Game over"}
                                whiteTime={'24'}
                                blackTime={'24'}
                                handleRestart={handleRematch}
                            />
                            <ChessWinModel
                                isOpen={gameWinModel}
                                handleClose={closeWinModel}
                                handleRestart={handleRematch}
                            />
                            <ChessDrawModel
                                isOpen = {gameDrawModel}
                                handleClose = {closeDrawModel}
                                handleRestart={handleRematch}
                            />
                        </>
                    )
                }}
            </HumanVsHuman>
        </div>
    );
}
const squareStyling = ({pieceSquare, history}) => {
    const sourceSquare = history.length && history[history.length - 1].from;
    const targetSquare = history.length && history[history.length - 1].to;

    return {
        [pieceSquare]: {backgroundColor: "rgb(135 101 73)"},
        ...(history.length && {
            [sourceSquare]: {
                backgroundColor: "rgb(135 101 73)"
            }
        }),
        ...(history.length && {
            [targetSquare]: {
                backgroundColor: "rgb(135 101 73)"
            }
        })
    };
};