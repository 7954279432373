import React from 'react';
import {Modal} from "react-bootstrap";

const Confirmation = (props) => {
    const {
        isOpen,
        handleClose,
        title,
        headerIcon,
        onConfirm,
        loading = false
    } = props;

    return (
        <Modal
            show={isOpen}
            onHide={handleClose}
            size="md"
            centered
        >
            <Modal.Header closeButton className="row mx-0 border-0 shadow-none">
                <div className="d-flex">
                    <img src={headerIcon} alt=""/>
                    <h4 className="modal-title text-white ms-2">
                        {title}
                    </h4>
                </div>
            </Modal.Header>
            <Modal.Body className="text-white">
                {props.modalBody}
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn border-0 font-13 fw-bold py-2 btn-hover ms-2"
                    style={{backgroundColor: "#42dbcb"}}
                    onClick={handleClose}
                > No, Cancel
                </button>
                <button
                    className="danger-btn btn-hover color-5"
                    onClick={onConfirm}
                >
                    {loading ? (
                        <div
                            className="spinner-border spinner-border-sm text-warning"
                            role="status"
                        >
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    ) : "Confirm"}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default Confirmation;