import React, {useRef, useEffect} from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import {authActions, referEarnActions} from "../../../_store";

import handleIcon from "../../assets/images/icon/页面-1.svg"
import * as Yup from "yup";
import {toast} from "react-toastify";
import {Modal} from "react-bootstrap";


export default function ApplyHandleModal({isOpen, handleClose}) {

    const referralHandleFormRef = useRef();

    // Redux Methods
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const loading = useSelector(x => x.referEarn.loading);
    const referralError = useSelector(x => x.referEarn.error);
    const referralResponse = useSelector(x => x.referEarn.referral);

    function _handleSubmit(values) {
        values.user_id = authUser?.user_id;
         dispatch(referEarnActions.createReferral({values})).then((response) => {
            if (response.payload?.message) {
                toast.success(response.payload.message);
                const values = authUser && authUser.user_id;
                dispatch(authActions.getUserDetails(values))
            }
            if (response?.error) {
                toast.error(response.error.message);
            } else {
                referralHandleFormRef?.current?.resetForm();
                return handleClose(true);
            }
        });
    }

    // if (referralResponse) {
    //     //document.querySelectorAll(".modal-backdrop").forEach(e => e.remove());
    //     return handleClose(true);
    // }

    const handleCloseModel = () => {
        return handleClose();
    }

    return (
        <>
            <Modal
                show={isOpen}
                onHide={handleCloseModel}
                centered
            >
                <Modal.Header closeButton>
                    <div className="modal-header row mx-0 border-0 shadow-none">
                        <div className="col-md-12 d-flex">
                            <img src={handleIcon} alt="" style={{width: "25px"}}/>
                            <h4 className="modal-title text-white ms-2">Apply for handle</h4>
                        </div>
                    </div>
                </Modal.Header>
                <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    referral: "",
                                }}
                                innerRef={referralHandleFormRef}
                                validationSchema={Yup.object().shape({
                                    referral: Yup.string().required("Referral is required!")
                                })}
                                onSubmit={(values) => {
                                    _handleSubmit(values)
                                }}
                            >
                                {({values, errors, touched, handleReset, handleSubmit}) => (
                                    <Form>
                                        <div>
                                            <div className="modal-body row mx-0">
                                                <div className="col mx-2 mt-2">
                                                    <div className="material-textfield">
                                                        <Field
                                                            type="text"
                                                            name="referral"
                                                            placeholder="Enter Handle"
                                                        />
                                                    </div>
                                                    <ErrorMessage name="referral" component="span"
                                                                  className="invalid-feedback">
                                                        {msg => <div style={{color: 'red'}}>{msg}</div>}
                                                    </ErrorMessage>
                                                    {referralError &&
                                                        <p className="text-center text-wrap text-danger mt-3 mb-0">{referralError.message}</p>}
                                                </div>
                                            </div>

                                            <div className="modal-footer border-0 mx-0">
                                                <div className="row w-100">
                                                    <div className="col-md-6 my-2">
                                                        <button className="login-btn btn-hover color-1" type="submit">
                                                            {loading ?
                                                                <div
                                                                    className="spinner-border spinner-border-sm text-warning"
                                                                    role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div> : 'Start Earning'}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                </Formik>

            </Modal>
        </>
    );

}
