import React from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

function SidebarMenu() {
    const pathname = useLocation().pathname;
    const navigate = useNavigate();
    const authUser = useSelector(x => x.auth.user);

    const navigateTo = (link) => {
        if (authUser) {
            navigate(link)
        }
    };

    const getActiveTab = (link = []) => {
        if (!authUser) {
            return "opacity-50 cursor-default";
        }
        if (_.includes(link, pathname)) {
            return "active";
        }
        return "";
    }

    return (
        <div className="col-md-1  col-md-1 px-0 pt-5 sidebarMenu">
            <nav className="side-nav align-items-start pt-5">
                <ul className="nav-menu mb-0">
                    <li className={"nav-item " + (pathname === '/' || pathname === '/home' ? 'active' : '')}>
                        <Link to="/">
                            <img className="mx-auto icon-white" src="/assets/images/icon/home.svg" alt=""/>
                            <img className="mx-auto icon-green" src="/assets/images/icon/home-green.svg" alt=""/>
                            <span className="font-10">Home</span>
                        </Link>
                    </li>
                    <li className={"nav-item " + (getActiveTab(["/favorite"]))}>
                        <div onClick={() => navigateTo("/favorite")}>
                            <img className="mx-auto icon-white" src="/assets/images/icon/star_icon.svg" alt=""/>
                            <img className="mx-auto icon-green" src="/assets/images/icon/star-green.svg" alt=""/>
                            <span className="font-10">Favorite</span>
                        </div>
                    </li>
                    <li className={"nav-item " + (getActiveTab(["/friends"]))}>
                        <div onClick={() => navigateTo('/friends')}>
                            <img className="mx-auto icon-white" src="/assets/images/icon/users-icon.svg" alt=""/>
                            <img className="mx-auto icon-green" src="/assets/images/icon/users-green.svg" alt=""/>
                            <span className="font-10">Friends</span>
                        </div>
                    </li>
                    <li className={"nav-item " + (pathname.includes('/chat') ? 'active' : '')}>
                        <Link to="/chat">
                            <img className="mx-auto icon-white" src="/assets/images/icon/chat_icon.svg" alt=""/>
                            <img className="mx-auto icon-green" src="/assets/images/icon/chat-icon-green.svg"
                                 alt=""/>
                            <span className="font-10">Chat</span>
                        </Link>
                    </li>
                    <li className={"nav-item " + (pathname === '/weekly_reward' ? 'active' : '')}>
                        <Link to="/weekly_reward">
                            <img className="mx-auto icon-white" src="/assets/images/icon/reward-white.svg"
                                 style={{width: "20px"}} alt=""/>
                            <img className="mx-auto icon-green" src="/assets/images/icon/reward-green.svg"
                                 style={{width: "20px"}} alt=""/>
                            <span className="font-10">Reward</span>
                        </Link>
                    </li>
                    <li className={"nav-item " + (getActiveTab(["/shop"]))}>
                        <div onClick={() => navigateTo("/shop")}>
                            <img className="mx-auto icon-white" src="/assets/images/icon/shopping_icon.svg" alt=""/>
                            <img className="mx-auto icon-green" src="/assets/images/icon/shopping_icon-green.svg"
                                 alt=""/>
                            <span className="font-10">Store</span>
                        </div>
                    </li>
                    <li className={"nav-item " + (pathname === '/refer_earn' ? 'active' : '')}>
                        <Link to="/refer_earn">
                            <img className="mx-auto icon-white" src="/assets/images/icon/refer-icon.svg" alt=""/>
                            <img className="mx-auto icon-green" src="/assets/images/icon/refer-icon-green.svg"
                                 alt=""/>
                            <span className="font-10">Refer & Earn</span>
                        </Link>
                    </li>
                    <li className={"nav-item " + (getActiveTab(["/virtua_swap"]))}>
                        <div onClick={() => navigateTo("/virtua_swap")}>
                            <img className="mx-auto" src="/assets/images/icon/virtua.svg" alt=""/>
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default SidebarMenu;
