import React from 'react';
import {Modal} from "react-bootstrap";
import group2344Icon from "../assets/images/icon/Icon ionic-logo-game-controller-b.svg";
import trophy from "../assets/images/winner-trophy-image.png"
import virtualCoin from "../assets/images/icon/virtua-coin.svg"
import upArrow from "../assets/images/up-arrow.svg"
import {useNavigate} from "react-router-dom";

const ChessWinModel = (props) => {
    const navigate = useNavigate();
    const { isOpen, handleClose , handleRestart} = props;

    return (
        <Modal
            show={isOpen}
            onHide={handleClose}
            size="md"
            centered
        >
            <Modal.Header closeButton className="row mx-0 border-0 shadow-none pb-0">
                <div className={'d-flex align-items-center mx-5'}>
                    <div>
                        <img src={trophy} alt={'winner'} style={{width:102}} />
                    </div>
                    <div className="d-flex flex-column align-items-center w-50">
                        <h4 className="font-oval-single ms-2 font-34">
                            <span className="text-warning" style={{color:'#fb8c11',letterSpacing:'3px',fontSize:42}}>Winner</span>
                            <span className="text-white ms-2" style={{letterSpacing:7}}>1-0</span>
                        </h4>
                        <span className="text-white"><img src={virtualCoin} alt={'coin'}/> 15</span>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body className="text-white">
                <div className={'d-flex py-4 justify-content-center gap-5'}>
                    <div className="d-flex flex-column align-items-center">
                        <img src="/assets/images/icon/icon-info-circle.svg" alt="avatar.png" style={{width: 45}}/>
                        <span className="text-white-50 mt-1 font-12">Moves</span>
                        <span>45</span>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                        <img src="/assets/images/avatar.png" alt="avatar.png" style={{width: 45}}/>
                        <span className="text-white-50 mt-1 font-12">James</span>
                        <span>27m 33s</span>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                        <img src="/assets/images/avatar.png" alt="avatar.png" style={{width: 45}}/>
                        <span className="text-white-50 mt-1 font-12">You</span>
                        <span>45m 13s</span>
                    </div>
                </div>
                <div className="d-flex justify-content-center gap-2">
                    <button
                        type="button"
                        className="btn font-13 fw-bold d-flex align-items-center gap-1 color-1"
                        style={{backgroundColor:'#ffbb5b'}}
                        onClick={() => navigate("/")}
                    >
                        <img src={upArrow} alt="home" style={{width: 15}}/>
                    </button>
                    <button
                        type="button"
                        className="btn font-13 fw-bold btn-hover d-flex align-items-center gap-1 color-1"
                        onClick={() => navigate("/")}
                    >
                        <img src="/assets/images/icon/home.png" alt="home" style={{width: 15}}/>
                        Home
                    </button>
                    <button
                        type="button"
                        className="btn font-13 fw-bold btn-hover d-flex align-items-center gap-1 color-4"
                        onClick={handleRestart}
                    >
                        <img src="/assets/images/icon/restartGame.png" alt="restart" style={{width: 15}}/>
                        Rematch
                    </button>
                    <button
                        type="button"
                        className="btn font-13 fw-bold btn-hover d-flex align-items-center gap-1 color-6"
                        onClick={handleRestart}
                    >
                        <img src="/assets/images/icon/plusIcon.png" alt="restart" style={{width: 15}}/>
                        New Game
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ChessWinModel;