export const timeCalculate = (date) => {
    const currentDate = new Date();
    const pastDate = new Date(date);

    const timeDifference = currentDate.getTime() - pastDate.getTime();
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
        return years === 1 ? '1 year ago' : `${years} years ago`;
    } else if (months > 0) {
        return months === 1 ? '1 month ago' : `${months} months ago`;
    } else if (days > 0) {
        return days === 1 ? '1 day ago' : `${days} days ago`;
    } else if (hours > 0) {
        return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
    } else if (minutes > 0) {
        return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
    } else {
        return 'Just now';
    }
}

export function addOrdinalIndicator(num = 0) {
    if (num === 0 || !num) {
        return 0;
    }
    if (num % 100 >= 11 && num % 100 <= 13) {
        return num + "<sup>th</sup>";
    } else {
        switch (num % 10) {
            case 1: return num + "<sup>st</sup>";
            case 2: return num + "<sup>nd</sup>";
            case 3: return num + "<sup>rd</sup>";
            default: return num + "<sup>th</sup>";
        }
    }
}