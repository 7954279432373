import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import svgBackImg from "../../assets/images/icon/Group 2344.svg";
import svgPersonImg from "../../assets/images/icon/Icon ionic-ios-person-add.svg";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {authActions} from "../../../_store";
import Cookies from "js-cookie";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {Modal} from "react-bootstrap";

const API_URL = process.env.REACT_APP_API_URL;
export const OTP_URL = `${API_URL}/user/otp/verify`;
export const RESEND_OTP_URL = `${API_URL}/user/otp`;

export default function OTPConfirmation({isOpen, handleClose, handleBack, value}) {
    const [accountRecoveryModal, setAccountRecoveryModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isValid, setIsValid] = useState("");
    const [email, setEmail] = useState("");
    const [otpValues, setOtpValues] = useState({otpValue1: '', otpValue2: '', otpValue3: '', otpValue4: ''});
    const [otpVerify, setOtpVerify] = useState();

    const items = JSON.parse(localStorage.getItem("register"));
    const authUser = useSelector((x) => x.auth.user);
    const regOtpFormRef = useRef();
    const otpValue1Ref = useRef(null);
    const otpValue2Ref = useRef(null);
    const otpValue3Ref = useRef(null);
    const otpValue4Ref = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (otpValue1Ref.current) {
            otpValue1Ref.current.focus();
        }
    }, [otpValue1Ref]);
    useEffect(() => {
        if (items?.email) {
            setEmail(items?.email);
        } else {
            setEmail(value?.email);
        }
    }, [value, items]);

    const onChange = (event, valRef) => {
        const {name, value} = event.target;

        if (/[0-9]/.test(event.target.value) && valRef) {
            valRef.current.focus();
        }

        setOtpValues(prevValues => {
            const newValues = {...prevValues, [name]: value};
            setOtpVerify(Object.values(newValues).join(''));
            return newValues;
        });
    };

    const resendEmail = () => {
        axios.post(RESEND_OTP_URL, {email: email}).then((res) => {
            if (!res.data.error) {
            } else {
                setIsValid(res.data.message);
            }
        });
    };

    return (
        <Modal
            show={isOpen}
            onHide={() => {
                regOtpFormRef?.current?.resetForm();
                handleClose(false);
            }}
            size="md"
            centered
        >
            <Modal.Header closeButton className="row mx-0 border-0 shadow-none">
                <div className="col-md-12 d-flex">
                    <img src={svgPersonImg} alt="" className="pe-2"/>
                    &nbsp;&nbsp;
                    <h4 className="modal-title text-white" id="otpModalLable">
                        Confirmation
                    </h4>
                </div>
            </Modal.Header>
            <Formik
                initialValues={{
                    otpValue1: "",
                    otpValue2: "",
                    otpValue3: "",
                    otpValue4: "",
                }}
                innerRef={regOtpFormRef}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                    otpValue1: Yup.string().matches(
                        /[0-9]/,
                        "Please enter only digit"
                    ),
                    otpValue2: Yup.string().matches(
                        /[0-9]/,
                        "Please enter only digit"
                    ),
                    otpValue3: Yup.string().matches(
                        /[0-9]/,
                        "Please enter only digit"
                    ),
                    otpValue4: Yup.string().matches(
                        /[0-9]/,
                        "Please enter only digit"
                    ),
                })}
                onSubmit={(values) => {
                    setLoading(true);
                    const authOtp = Number(`${values.otpValue1}${values.otpValue2}${values.otpValue3}${values.otpValue4}`);
                    const user_id = authUser && authUser.user_id;
                    axios.post(OTP_URL, {email: email, auth_otp: authOtp}).then((response) => {
                        if (!response.data.error) {
                            setAccountRecoveryModal(true);
                            setOtpValues({otpValue1: '', otpValue2: '', otpValue3: '', otpValue4: ''});
                            dispatch(authActions.deleteAccount({
                                account_id: user_id,
                                user_id: user_id
                            })).then((res) => {
                                if (!(res.error && res.error.message)) {
                                    handleClose(false);
                                    toast.success(res.payload.message);
                                    localStorage.clear();
                                    Cookies.remove('jwt');
                                }
                                navigate("/");
                            });
                            regOtpFormRef?.current?.resetForm();
                        } else {
                            toast.error(response.data.message);
                        }
                        setLoading(false);
                    }).catch((err) => {
                        setIsValid(err);
                        handleClose(true);
                        setLoading(false);
                    })
                }}
            >
                <Form>
                    <div className="modal-body row mx-0 py-4">
                        {/*{error ? <p className="text-danger">{errMessage}</p> : ''}*/}
                        <p className="text-white mb-0"><small>Enter the code sent on your email</small></p>
                        <p className="text-white mb-0 fw-bold"><small style={{color: "yellow"}}>{authUser && authUser?.email}</small></p>
                        {isValid ? <p className="text-danger">{isValid}</p> : ""}
                        <div className="input-container d-flex flex-row justify-content-start mt-3 mb-3">
                            <div className="col-2">
                                <Field
                                    type="text"
                                    innerRef={otpValue1Ref}
                                    className="text-center py-3 form-control rounded"
                                    maxLength="1"
                                    placeholder="-"
                                    tabIndex={1}
                                    name="otpValue1"
                                    value={otpValues.otpValue1}
                                    onInput={(event) => {
                                        onChange(event, otpValue2Ref);
                                    }}
                                />
                            </div>
                            <div className="col-2 ms-3">
                                <Field
                                    type="text"
                                    innerRef={otpValue2Ref}
                                    className="text-center py-3 form-control rounded"
                                    maxLength="1"
                                    placeholder="-"
                                    tabIndex={2}
                                    name="otpValue2"
                                    value={otpValues.otpValue2}
                                    onInput={(event) => {
                                        onChange(event, otpValue3Ref);
                                    }}
                                />
                            </div>
                            <div className="col-2 ms-3">
                                <Field
                                    type="text"
                                    innerRef={otpValue3Ref}
                                    className="text-center py-3 form-control rounded"
                                    maxLength="1"
                                    placeholder="-"
                                    tabIndex={3}
                                    name="otpValue3"
                                    value={otpValues.otpValue3}
                                    onInput={(event) => {
                                        onChange(event, otpValue4Ref);
                                    }}
                                />
                            </div>
                            <div className="col-2 ms-3">
                                <Field
                                    type="text"
                                    innerRef={otpValue4Ref}
                                    className="text-center py-3 form-control rounded"
                                    maxLength="1"
                                    placeholder="-"
                                    tabIndex={4}
                                    name="otpValue4"
                                    value={otpValues.otpValue4}
                                    onInput={(event) => {
                                        onChange(event);
                                    }}
                                />
                            </div>
                        </div>
                        <ErrorMessage
                            name="otpValue1"
                            component="span"
                            className="invalid-feedback"
                        >
                            {(msg) => <div style={{color: "red"}}>{msg}</div>}
                        </ErrorMessage>
                        <ErrorMessage
                            name="otpValue2"
                            component="span"
                            className="invalid-feedback"
                        >
                            {(msg) => <div style={{color: "red"}}>{msg}</div>}
                        </ErrorMessage>
                        <ErrorMessage
                            name="otpValue3"
                            component="span"
                            className="invalid-feedback"
                        >
                            {(msg) => <div style={{color: "red"}}>{msg}</div>}
                        </ErrorMessage>
                        <ErrorMessage
                            name="otpValue4"
                            component="span"
                            className="invalid-feedback"
                        >
                            {(msg) => <div style={{color: "red"}}>{msg}</div>}
                        </ErrorMessage>
                        <p className="text-muted font-13">
                            Didn't receive the code?{" "}
                            <button
                                type="button"
                                className="color-light-blue fw-bold transparent-link p-0"
                                onClick={resendEmail}
                            >
                                Resend Email
                            </button>
                        </p>
                        <p className="text-white font-13 fw-bold m-0 mt-3">
                            <small>Your account delete is being processed?</small>
                        </p>
                    </div>
                    <div className="modal-footer cursor-default border-0 mx-0">
                        <div className="row w-100">
                            <div className="col-md-12 my-1 d-flex">
                                <button
                                    type="button"
                                    className="no-btn-small"
                                    data-bs-target={`#${accountRecoveryModal ? 'registerModel' : 'forgotPassword'}`}
                                    role="modal"
                                    data-bs-toggle="modal"
                                    onClick={() => {
                                        handleBack(true)
                                    }}
                                >
                                    <img src={svgBackImg} alt=""/>
                                </button>
                                <button
                                    type="submit"
                                    className="login-btn ms-3 btn-hover color-1"
                                    disabled={otpVerify ? otpVerify.toString().length !== 4 : true}
                                >
                                    {loading ? (
                                        <div
                                            className="spinner-border spinner-border-sm text-warning"
                                            role="status"
                                        >
                                          <span className="visually-hidden">
                                            Loading...
                                          </span>
                                        </div>
                                    ) : (
                                        "Confirm"
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </Formik>
        </Modal>
    );
}
