import {Component} from "react";
import PropTypes from "prop-types";
import Chess from "chess.js";
import SuccessModel from "../../../components/SuccessModel";
import ChessWinModel from "../../../components/SuccessModel/chessWinModel";
import ChessDrawModel from "../../../components/SuccessModel/chessDrawModel";

const STOCKFISH = window.STOCKFISH;
const game = new Chess();

class StockFish extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fen: "start",
            isGameOver: false,
            startSquare: null,
            endSquare: null,
            whiteTimes: [],
            blackTimes: [],
            startTime: null,
            gameEnded: false,
            totalGameTime: 0,
            whiteTotalTime: 0,
            blackTotalTime: 0,
            currentPlayer: 'white',
            showRestartButton: false,
            endTime: null,
            movesHistory: [],
        };
    }

    static propTypes = {children: PropTypes.func};

    componentDidMount() {
        const startTime = Date.now();
        this.engineGame().prepareMove();
        this.handleRestart();
        this.setState({
            fen: game.fen(),
            isGameOver: false,
            showRestartButton: false,
            startTime: startTime,
            endTime: startTime
        }, () => {
            this.engineGame().start();
        });
    }

    onDrop = ({sourceSquare, targetSquare}) => {
        const move = game.move({
            from: sourceSquare,
            to: targetSquare,
            promotion: "q"
        });

        if (move === null) return;

        const moveInfo = {
            move: `${move.from}-${move.to}`,
            piece: move.piece,
            player: this.state.currentPlayer,
            startTime: this.state.startTime,
            endTime: Date.now(),
        };

        this.setState(prevState => ({
            movesHistory: [...prevState.movesHistory, moveInfo]
        }));

        const endTime = Date.now();
        const moveTime = (endTime - this.state.startTime) / 1000;

        if (this.state.currentPlayer === 'white') {
            this.setState(prevState => ({
                whiteTimes: [...prevState.whiteTimes, moveTime]
            }));
        } else {
            this.setState(prevState => ({
                blackTimes: [...prevState.blackTimes, moveTime]
            }));
        }

        if (this.state.currentPlayer === 'white') {
            this.setState(prevState => ({
                whiteTotalTime: prevState.whiteTotalTime + moveTime
            }));
        } else {
            this.setState(prevState => ({
                blackTotalTime: prevState.blackTotalTime + moveTime
            }));
        }

        game.move({
            from: sourceSquare,
            to: targetSquare,
            piece: move.piece.toLowerCase(),
        });

        this.setState({
            fen: game.fen(),
            startSquare: sourceSquare,
            endSquare: targetSquare,
            startTime: endTime,
            currentPlayer: this.state.currentPlayer === 'white' ? 'black' : 'white'
        });

        if (game.game_over() || game.in_checkmate()) {
            const totalGameTime = (endTime - this.state.startTime) / 1000;
            this.setState({
                gameEnded: true,
                totalGameTime: totalGameTime
            });
        }

        this.engineGame().prepareMove();
    };

    engineGame = options => {
        options = options || {};
        let engine =
            typeof STOCKFISH === "function"
                ? STOCKFISH()
                : new Worker(options.stockfishjs || "stockfish.js");
        let eviler =
            typeof STOCKFISH === "function"
                ? STOCKFISH()
                : new Worker(options.stockfishjs || "stockfish.js ");
        let engineStatus = {};
        let time = {wtime: 100, btime: 100, winc: 100, binc: 100};
        let playerColor = "black";
        let clockTimeoutID = null;
        let announced_game_over;

        function uciCmd(cmd, which) {
            (which || engine).postMessage(cmd);
        }

        uciCmd("uci");

        function clockTick() {
            let t =
                (time.clockColor === "white" ? time.wtime : time.btime) +
                time.startTime -
                Date.now();
            let timeToNextSecond = 1000;
            clockTimeoutID = setTimeout(clockTick, timeToNextSecond);
        }

        function stopClock() {
            if (clockTimeoutID !== null) {
                clearTimeout(clockTimeoutID);
                clockTimeoutID = null;
            }
            if (time.startTime > 0) {
                let elapsed = Date.now() - time.startTime;
                time.startTime = null;
                if (time.clockColor === "white") {
                    time.wtime = Math.max(0, time.wtime - elapsed);
                } else {
                    time.btime = Math.max(0, time.btime - elapsed);
                }
            }
        }

        function startClock() {
            if (game.turn() === "w") {
                time.wtime += time.winc;
                time.clockColor = "white";
            } else {
                time.btime += time.binc;
                time.clockColor = "black";
            }
            time.startTime = Date.now();
            clockTick();
        }

        function get_moves() {
            let moves = "";
            let history = game.history({verbose: true});

            for (let i = 0; i < history.length; ++i) {
                let move = history[i];
                moves +=
                    " " + move.from + move.to + (move.promotion ? move.promotion : "");
            }

            return moves;
        }

        const prepareMove = () => {
            stopClock();
            let turn = game.turn() === "w" ? "white" : "black";
            if (!game.game_over()) {
                if (turn !== playerColor) {
                    uciCmd("position startpos moves" + get_moves());
                    uciCmd("position startpos moves" + get_moves(), eviler);
                    uciCmd("eval", eviler);

                    if (time && time.wtime) {
                        uciCmd(
                            "go " +
                            (time.depth ? "depth " + time.depth : "") +
                            " wtime " +
                            time.wtime +
                            " winc " +
                            time.winc +
                            " btime " +
                            time.btime +
                            " binc " +
                            time.binc
                        );
                    } else {
                        uciCmd("go " + (time.depth ? "depth " + time.depth : ""));
                    }
                }
                if (game.history().length >= 2 && !time.depth && !time.nodes) {
                    startClock();
                }
            }
        };

        eviler.onmessage = function (event) {
            let line;

            if (event && typeof event === "object") {
                line = event.data;
            } else {
                line = event;
            }

            if (
                line === "uciok" ||
                line === "readyok" ||
                line.substr(0, 11) === "option name"
            ) {
                return;
            }
        };

        engine.onmessage = event => {
            let line;

            if (event && typeof event === "object") {
                line = event.data;
            } else {
                line = event;
            }
            if (line === "uciok") {
                engineStatus.engineLoaded = true;
            } else if (line === "readyok") {
                engineStatus.engineReady = true;
            } else {
                let match = line.match(/^bestmove ([a-h][1-8])([a-h][1-8])([qrbn])?/);
                if (match) {
                    game.move({from: match[1], to: match[2], promotion: match[3]});
                    this.setState({fen: game.fen()});
                    prepareMove();
                    uciCmd("eval", eviler);
                } else if (
                    (match = line.match(/^info .*\bdepth (\d+) .*\bnps (\d+)/))
                ) {
                    engineStatus.search = "Depth: " + match[1] + " Nps: " + match[2];
                }

                if ((match = line.match(/^info .*\bscore (\w+) (-?\d+)/))) {
                    let score = parseInt(match[2], 10) * (game.turn() === "w" ? 1 : -1);
                    if (match[1] === "cp") {
                        engineStatus.score = (score / 100.0).toFixed(2);
                    } else if (match[1] === "mate") {
                        engineStatus.score = "Mate in " + Math.abs(score);
                    }

                    if ((match = line.match(/\b(upper|lower)bound\b/))) {
                        engineStatus.score =
                            ((match[1] === "upper") === (game.turn() === "w")
                                ? "<= "
                                : ">= ") + engineStatus.score;
                    }
                }
            }
            this.displayStatus(engineStatus);
        };

        return {
            start: function () {
                uciCmd("ucinewgame");
                uciCmd("isready");
                engineStatus.engineReady = false;
                engineStatus.search = null;
                prepareMove();
                announced_game_over = false;
            },
            prepareMove: function () {
                prepareMove();
            },
            reset: function () {
                clearTimeout(clockTimeoutID);
                clockTimeoutID = null;
                time.wtime = 100;
                time.btime = 100;
                time.winc = 100;
                time.binc = 100;
                time.startTime = null;
                time.clockColor = null;
                engineStatus.engineLoaded = false;
                engineStatus.engineReady = false;
                engineStatus.search = null;
                engineStatus.score = null;
                game.reset();
            }
        };
    };

    displayStatus() {
        if (game.in_checkmate()) {
            this.setState({ isGameOver: true });
        }
    }

    handleDragOverSquare = (square) => {
        this.setState({startSquare: square});
    };

    handleRestart = () => {
        this.engineGame().reset();
        this.setState({
            fen: game.fen(),
            isGameOver: false,
            showRestartButton: false,
            startSquare: null,
            endSquare: null,
            whiteTimes: [],
            blackTimes: [],
            startTime: null,
            gameEnded: false,
            totalGameTime: 0,
            whiteTotalTime: 0,
            blackTotalTime: 0,
            currentPlayer: 'white',
            endTime: null,
            movesHistory: [],
        }, () => {
            this.engineGame().start();
        });
    };

    render() {
        const {whiteTotalTime, blackTotalTime, movesHistory} = this.state;

        return (
            <>
                {this.props.children({
                    position: this.state.fen,
                    onDrop: this.onDrop,
                    onDragOverSquare: this.handleDragOverSquare,
                })}
                <SuccessModel
                    isOpen={game.turn() === "b" && game.game_over()}
                    handleClose={() => {
                        this.setState({isGameOver: false});
                        this.componentDidMount();
                    }}
                    blackMoves={movesHistory?.length}
                    modalBody={this.state.status}
                    title={"Game over"}
                    whiteTime={formatTime(whiteTotalTime)}
                    blackTime={formatTime(blackTotalTime)}
                    handleRestart={()=> {
                        this.handleRestart();
                        this.componentDidMount();
                    }}
                />
                <ChessWinModel
                    isOpen={game.turn() === "w" && game.game_over()}
                    handleClose={() => {
                        this.setState({isGameOver: false});
                        this.componentDidMount();
                    }}
                    handleRestart={()=> {
                        this.handleRestart();
                        this.componentDidMount();
                    }}
                />
                <ChessDrawModel/>
            </>
        );
    }
}

export default StockFish;

function formatTime(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    const formattedHours = hours > 0 ? `${hours}h ` : '';
    const formattedMinutes = minutes > 0 ? `${minutes}m ` : '';
    const formattedSeconds = `${seconds}s`;

    return formattedHours + formattedMinutes + formattedSeconds;
}