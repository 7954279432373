import React from 'react';
import {Modal} from "react-bootstrap";
import group2344Icon from "../assets/images/icon/Icon ionic-logo-game-controller-b.svg";
import {useNavigate} from "react-router-dom";

const SuccessModel = (props) => {
    const navigate = useNavigate();
    const { isOpen, handleClose, handleRestart, whiteTime, blackTime, blackMoves } = props;

    return (
        <Modal
            show={isOpen}
            onHide={handleClose}
            size="md"
            centered
        >
            <Modal.Header closeButton className="row mx-0 border-0 shadow-none">
                <div className="d-flex flex-column align-items-center">
                    <h4 className="font-oval-single ms-2 font-34">
                        <span className="text-danger">You Lost</span>
                        <span className="text-white ms-2">0-1</span>
                    </h4>
                    <span className="text-white">"Success is final, Failure is not fate!"</span>
                </div>
            </Modal.Header>
            <Modal.Body className="text-white">
                <div className={'d-flex py-4 justify-content-center gap-5'}>
                    <div className="d-flex flex-column align-items-center">
                        <img src="/assets/images/icon/icon-info-circle.svg" alt="avatar.png" style={{width: 45}}/>
                        <span className="text-white-50 mt-1 font-12">Moves</span>
                        <span>{blackMoves}</span>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                        <img src="/assets/images/avatar.png" alt="avatar.png" style={{width: 45}}/>
                        <span className="text-white-50 mt-1 font-12">James</span>
                        <span>{whiteTime}</span>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                        <img src="/assets/images/avatar.png" alt="avatar.png" style={{width: 45}}/>
                        <span className="text-white-50 mt-1 font-12">You</span>
                        <span>{blackTime}</span>
                    </div>
                </div>
                <div className="d-flex justify-content-center gap-2">
                    <button
                        type="button"
                        className="btn font-13 fw-bold btn-hover d-flex align-items-center gap-1 color-1"
                        onClick={()=>navigate("/")}
                    >
                        <img src="/assets/images/icon/home.png" alt="home" style={{width:15}}/>
                        Home
                    </button>
                    <button
                        type="button"
                        className="btn font-13 fw-bold btn-hover d-flex align-items-center gap-1 color-4"
                        onClick={handleRestart}
                    >
                        <img src="/assets/images/icon/restartGame.png" alt="restart" style={{width: 15}}/>
                        Rematch
                    </button>
                    <button
                        type="button"
                        className="btn font-13 fw-bold btn-hover d-flex align-items-center gap-1 color-6"
                        onClick={handleRestart}
                    >
                        <img src="/assets/images/icon/plusIcon.png" alt="restart" style={{width: 15}}/>
                        New Game
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SuccessModel;