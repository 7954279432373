import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import personAdd from "../../assets/images/icon/Icon ionic-ios-person-add.svg";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import OTPConfirmation from "./OTPConfirmation";
import axios from "axios";
import {RESEND_OTP_URL} from "./RegOtpModal";

const DeleteAccountConfirmation = ({isOpen, handleClose}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authUser = useSelector((x) => x.auth.user);
    const [authLoading, setAuthLoading] = useState(false);
    const [isOpenOTPModal, setIsOpenOTPModal] = useState(false);

    const closeOTPModal = (value) => {
        setIsOpenOTPModal(value);
    }
    const handleBackStep = () => {
        setIsOpenOTPModal(false);
        handleClose(true);
    }

    const nextStep = () => {
        setIsOpenOTPModal(true);
        handleClose(false);
        axios.post(RESEND_OTP_URL, {email: authUser.email}).then((res) => {
            setAuthLoading(false);
        })
    };

    return (
        <>
            <Modal
                show={isOpen}
                onHide={()=> handleClose(false)}
                size="md"
                centered
            >
                <Modal.Header closeButton className="row mx-0 border-0 shadow-none">
                    <div className="d-flex">
                        <img src={personAdd} alt=""/>
                        <h4 className="modal-title text-white ms-2">
                            Delete account
                        </h4>
                    </div>
                </Modal.Header>
                <Modal.Body className="text-white">
                    <p className="mb-2">
                        We are sad that you leaving us!,
                        contact our support if you have any concerns at
                        <a href="mailto:Support@virtuacorp.com.au" target="_blank" className="ms-1">
                            Support@virtuacorp.com.au
                        </a>
                    </p>
                    <br/>
                    <p>
                        Are you sure you want to delete your account!, <br/>
                        This action cannot be reversed,<br/>
                        all your wallet balance will be gone.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn border-0 font-13 fw-bold py-2 btn-hover color-1 ms-2"
                        style={{backgroundColor: "#42dbcb"}}
                        id="button-addon3"
                        onClick={()=> handleClose(false)}
                    > No cancel
                    </button>
                    <button
                        className="btn border-0 bg-danger text-white fw-bold font-13 btn-hover color-3 py-2 me-2"
                        id="button-addon2"
                        onClick={nextStep}
                    >
                        {authLoading ? (
                            <div
                                className="spinner-border spinner-border-sm text-warning"
                                role="status"
                            >
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        ) : "Yes, proceed"}
                    </button>
                </Modal.Footer>
            </Modal>
            <OTPConfirmation
                isOpen={isOpenOTPModal}
                handleClose={(value)=> closeOTPModal(value)}
                handleBack={handleBackStep}
                value={authUser}
                modelData={authUser}
            />
        </>
    );
};

export default DeleteAccountConfirmation;