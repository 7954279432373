import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchWrapper} from '../_helpers';
import Cookies from 'js-cookie';
import _ from "lodash";
// create slice

const name = 'auth';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({name, initialState, extraReducers});

// exports

export const authActions = {...slice.actions, ...extraActions};
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
    const userData = JSON.parse(localStorage.getItem("register"));
    return {
        // initialize state from local storage to enable user to stay logged in
        user: userData,
        error: null,
        loading: false,
        userData: null,
        avatarLists: [],
        bonusCredited: null,
    }
}

function createReducers() {
    return {
        logout
    };

    function logout(state) {
        state.user = null;
        //history.navigate('/login');
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/user`;
    const virtuaCoinsUrl = `${process.env.REACT_APP_VIRTUACOINS_URL}/user`;
    const AccountUrl = `${process.env.REACT_APP_API_URL}/account`;

    return {
        switchProfile: switchProfile(),
        login: login(),
        logOut: logOut(),
        getUserDetails: getUserDetails(),
        getUserAvatars: getUserAvatars(),
        deleteAccount: deleteAccount(),
        getJoiningBonus: getJoiningBonus(),
        getProfile: getProfile(),
    };

    function switchProfile() {
        return createAsyncThunk(
            `${name}/switchProfile`,
            async (data) => await fetchWrapper.post(`${baseUrl}/profile/switch`, { ...data.values})
        );
    }

    function getProfile() {
        return createAsyncThunk(
            `${name}/getProfile`,
            async (data) => await fetchWrapper.post(`${baseUrl}/profile/details`, data)
        );
    }


    function getJoiningBonus() {
        return createAsyncThunk(
            `${name}/getJoiningBonus`,
            async () => await fetchWrapper.post(`${virtuaCoinsUrl}/joiningBonus/credit`, {})
        )
    }

    function login() {
        return createAsyncThunk(
            `${name}/login`,
            async (data) => {
                const response = await fetchWrapper.post(`${baseUrl}/signin`, {
                    email: data.values.email,
                    password: data.values.password,
                    app_id: 'FLF2KVSBG72K6O'
                })
                return {...response,remember_me:data.values.remember_me};
            }
        )
    }

    function logOut() {
        return createAsyncThunk(
            `${name}/logout`,
            async (data) => await fetchWrapper.post(`${baseUrl}/signout`, {user_id: data.values.user_id})
        )
    }

    function getUserDetails() {
        return createAsyncThunk(
            `${name}/user/details`,
            async (data) => await fetchWrapper.post(`${baseUrl}/details`, {user_id: data})
        )
    }

    function getUserAvatars() {
        return createAsyncThunk(
            `${name}/user/avatar/get`,
            async () => await fetchWrapper.get(`${baseUrl}/avatar/get`)
        )
    }

    function deleteAccount() {
        return createAsyncThunk(
            `${name}/deleteAccount`,
            async (data) => await fetchWrapper.post(`${AccountUrl}/delete`, data)
        );
    }
}

function createExtraReducers() {
    return {
        ...switchProfile(),
        ...login(),
        ...logout(),
        ...getUserDetailData(),
        ...getUserAvatars(),
        ...deleteAccount(),
        ...getJoiningBonus(),
        ...getProfile(),
    };

    function switchProfile() {
        var {pending, fulfilled, rejected} = extraActions.switchProfile;
        return {
            [pending]: (state) => {
                state.error = null;
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                let user = {...state.user,...action.payload.data};
                Cookies.set('jwt', user.api_token, {expires: 7});
                localStorage.setItem('register', JSON.stringify(user));
                state.user = user;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
                state.loading = false;
            }
        };
    }

    function getProfile() {
        var {pending, fulfilled, rejected} = extraActions.getProfile;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.user = {
                    ...state.user,
                    ...action.payload.data,
                    profile_picture:`https://virtuagrid.com/${_.get(action.payload.data,"profile_picture","")}`
                };
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
                state.loading = false;
            }
        };
    }
    function login() {
        var {pending, fulfilled, rejected} = extraActions.login;
        return {
            [pending]: (state) => {
                state.error = null;
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                let user = action.payload.data;
                const api_token = user.api_token;
                Cookies.set('jwt', api_token, {expires: action.payload.remember_me ? 365 : 7});
                localStorage.setItem('register', JSON.stringify(user));
                state.user = user;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
                state.loading = false;
            }
        };
    }

    function logout() {
        var {pending, fulfilled, rejected} = extraActions.logOut;
        return {
            [pending]: (state) => {
                state.error = null;
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.user = null;
                state.loading = false;
                localStorage.removeItem('register');
                Cookies.remove('jwt');
            },
            [rejected]: (state, action) => {
                state.error = action.error;
                state.loading = false;
            }
        };
    }

    function getUserDetailData() {
        var {pending, fulfilled, rejected} = extraActions.getUserDetails;
        return {
            [pending]: (state) => {
                state.error = null;
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.user = action.payload.data;
                localStorage.setItem('register', JSON.stringify(action.payload.data));
                state.loading = false;
            },
            [rejected]: (state, action) => {
                localStorage.removeItem('register');
                Cookies.remove('jwt');
                state.error = action.error;
                state.user = null;
                state.loading = false;
            }
        };
    }
    function getUserAvatars() {
        var {pending, fulfilled, rejected} = extraActions.getUserAvatars;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.avatarLists = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }
    function deleteAccount() {
        var {pending, fulfilled, rejected} = extraActions.deleteAccount;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.user = null;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }

    function getJoiningBonus() {
        var {pending, fulfilled, rejected} = extraActions.getJoiningBonus;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state,action) => {
                state.loading = false;
                state.bonusCredited = action.payload.data.amount;
            },
            [rejected]: (state) => {
                state.loading = false;
                state.bonusCredited = false;
            }
        };
    }
}
