import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

export default function MaintenanceModeModel({isOpen}) {
    const navigate = useNavigate();
    const [maintenanceModeModel, setMaintenanceModeModel] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setMaintenanceModeModel(isOpen);
        }
    }, [isOpen]);
    return (
        <Modal
            show={maintenanceModeModel}
            onHide={()=> {
                setMaintenanceModeModel(false);
                navigate("/");
            }}
            size="lg"
            centered
        >
            <Modal.Header closeButton className="row mx-0 border-0 shadow-none">
                <h4 className="modal-title text-white text-center">Maintenance Mode</h4>
            </Modal.Header>
            <form>
                <div className="modal-body row mx-0">
                    <div className="text-center">
                        <img src="/assets/images/icon/Group 4682.svg" alt="group" height="200px" className="rounded py-4"/>
                        <p style={{color: '#b7b7b7', fontSize: '16px'}}>
                            Currently the system is undergoing Maintenance. Sorry for the<br/>
                            inconvenience. We will notify you once we are online.
                        </p>
                        <p style={{color: '#b7b7b7', fontSize: '16px', fontWeight: 'bold'}}>Thank you for your
                            Co-operations.</p>
                        <p style={{color: '#b7b7b7', fontSize: '16px', fontWeight: 'bold'}} className="m-0">Support
                            Team</p>
                        <p style={{color: '#fcd32c', fontSize: '16px', fontWeight: 'bold'}}>Tuti Games</p>
                    </div>
                </div>
            </form>
        </Modal>
    )
}
