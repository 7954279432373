import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBmkKAcQvakyL6_mhTHZUU9RIlGMhlq0Y8",
    authDomain: "tutigames-b7ddd.firebaseapp.com",
    projectId: "tutigames-b7ddd",
    storageBucket: "tutigames-b7ddd.appspot.com",
    messagingSenderId: "994485240238",
    appId: "1:994485240238:web:a821d10aabd026e6a1883f",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);