import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from 'react-redux';
import {applicationActions, weeklyRewardActions} from "../../_store";

import virtuaCoin from "../../components/assets/images/icon/virtua-coin.svg";
import smallGame1 from "../../components/assets/images/small-game1.svg";
import smallGame2 from "../../components/assets/images/small-game2.svg";
import smallGame3 from "../../components/assets/images/small-game3.svg";
import smallGame4 from "../../components/assets/images/small-game4.svg";
import shareGreenIcon from "../../components/assets/images/icon/share-green.svg";
import prize1Icon from "../../components/assets/images/prize1.png";
import firstPrize from "../../components/assets/images/1st.png";
import prize2Icon from "../../components/assets/images/prize2.png";
import secondPrize from "../../components/assets/images/2nd.png";
import prize3Icon from "../../components/assets/images/prize3.png";
import thirdPrize from "../../components/assets/images/3rd.png";
import playerIcon from "../../components/assets/images/icon/player.svg";
import player1 from "../../components/assets/images/icon/player1.svg";
import player2 from "../../components/assets/images/icon/player2.svg";
import player3 from "../../components/assets/images/icon/player3.svg";
import arrowRight from "../../components/assets/images/icon/Iconfeather-arrow-right.svg";

function WeeklyReward() {
    // Redux Methods
    const [active,setActive] = useState(13)
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const applicationListResponse = useSelector((x) => x.application.applicationItem);

    useEffect(() => {
        dispatch(applicationActions.getApplicationList());
    }, [dispatch]);


    const renderApplicationLists = () => {
        let lists = [];
        (applicationListResponse || []).forEach((application)=> {
            if (application.app_icon) {
                lists.push(
                    <div
                        key={application.id}
                        onClick={()=>setActive(application.id)}
                        className="row mt-3 ps-3"
                    >
                        <div className={`col-lg-12 col-md-12 p-2 games ${active === application.id ? "active" : ""}`}>
                            <div className="row">
                                <div className="col-lg-4 col-md-4">
                                    <img src={"https://virtuagrid.com/" + application.app_icon} className="img-fluid"/>
                                </div>
                                <div className="col-lg-8 col-md-8">
                                    <p className="text-white fw-bold mb-0">{application.app_name}</p>
                                    <button className="btn btn btn-warning rounded-2 py-1 font-13 px-2 fw-bold">
                                        <img src={virtuaCoin} style={{width: "20px"}}/>
                                        <span>0</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        })
        return lists;
    }
    return (
        <>
            <div className="row info">
                <div className="d-flex flex-row ps-3 pt-3">
                    <p className="font-oval-single font-27 text-white">Weekly Reward</p>
                    <p className="text-end border-secondary border-start pt-2 ps-2 pe-2 ms-auto yellow-heading font-13">
                        Last Week
                        <span> Total Reward <img src={virtuaCoin} style={{width: "25px"}} alt="virtuaCoin"/></span>
                        <span className="text-white font-oswald font-14 fw-bold"> 3,515</span>
                    </p>
                </div>
            </div>
            <div className="row border-top border-secondary">
                <div className="col-lg-2 col-md-2 pe-5 overflow-auto"  style={{height:"calc(100vh - 135px)"}}>
                    {renderApplicationLists()}
                </div>
                <div className="col-lg-10 col-md-10 background-light-grey">
                     <div className="row p-2">
                         <div className="d-flex flex-row">
                             <div className="col-lg-6 col-md-6">
                                 <p className="color-green font-oval-single font-27 mb-0">WEEKLY AWARDS <span><a
                                     className="btn ms-1 font-poppins font-10" id="profile-header-btn-yellow" href="#">Week 20</a></span>
                                 </p>
                                 <p className="text-white font-13">Season Ends In: 10 Days</p>
                             </div>
                             <div className="col-lg-6 col-md-6 text-end pt-3">
                                 <button className="btn background-orange font-oval-single font-16 text-white">TOP
                                     PLAYERS
                                 </button>
                                 <button
                                     className="btn bg-transparent font-oval-single font-16 text-secondary me-4">FRIENDS
                                     RANK
                                 </button>
                                 <a className="btn color-green font-13" id="profile-header-btn" href="#"><span><img
                                     src={shareGreenIcon} style={{width: "15px"}}/></span> SHARE</a>
                             </div>
                         </div>
                     </div>
                     <div className="row p-3 background-dark-grey-shade1">
                         <div className="col me-4 background-dark-yellow rounded-3 position-relative">
                             <div className="row p-3">
                                 <div className="col-lg-3 col-md-3">
                                     <img src={prize1Icon} style={{width: "43px"}}/>
                                 </div>
                                 <div className="col-lg-9 col-md-9">
                                     <p className="color-light-yellow font-16 mb-0">Spirit Point</p>
                                     <p className="text-white font-oswald fw-bold font-14 mb-0">900,000,000 PTS.</p>
                                     <p className="text-white font-12 mb-0"><img
                                         src={virtuaCoin} style={{width: "15px"}}/> 912</p>
                                 </div>
                             </div>
                             <img src={firstPrize}
                                  style={{width: "33px", position: "absolute", top: "77.5%", right: "-3.5%"}}/>
                         </div>
                         <div className="col me-4 position-relative">
                             <div className="row p-3 background-light-grey rounded-3">
                                 <div className="col-lg-3 col-md-3">
                                     <img src={prize2Icon} style={{width: "43px"}}/>
                                 </div>
                                 <div className="col-lg-9 col-md-9">
                                     <p className="color-light-yellow font-16 mb-0">Spirit Point</p>
                                     <p className="text-white font-oswald fw-bold font-14 mb-0">900,000,000 PTS.</p>
                                     <p className="text-white font-12 mb-0"><img
                                         src={virtuaCoin} style={{width: "15px"}}/> 912</p>
                                 </div>
                             </div>
                             <img src={secondPrize}
                                  style={{width: "33px", position: "absolute", top: "77.5%", right: "-3.5%"}}/>
                         </div>
                         <div className="col position-relative">
                             <div className="row p-3 background-dark-orange rounded-3">
                                 <div className="col-lg-3 col-md-3">
                                     <img src={prize3Icon} style={{width: "43px"}}/>
                                 </div>
                                 <div className="col-lg-9 col-md-9">
                                     <p className="color-light-yellow font-16 mb-0">Spirit Point</p>
                                     <p className="text-white font-oswald fw-bold font-14 mb-0">900,000,000 PTS.</p>
                                     <p className="text-white font-12 mb-0"><img
                                         src={virtuaCoin} style={{width: "15px"}}/> 912</p>
                                 </div>
                             </div>
                             <img src={thirdPrize}
                                  style={{width: "33px", position: "absolute", top: "77.5%", right: "-3.5%"}}/>
                         </div>
                     </div>
                     <div className="row p-3">
                         <div className="col-lg-12 col-md-12 px-0">
                             <div className="row pb-3 border-secondary border-bottom">
                                 <p className="color-green font-oval-single font-27 mb-0">CURRENT WEEK TOPPERS</p>
                             </div>
                             <div className="row px-4">
                                 <div className="col-lg-12 col-md-12">
                                     <div className="row">
                                         <div className="d-flex flex-row py-2 border-bottom border-secondary">
                                             <div className="gray-background-image ps-4 pe-5">
                                                 <p className="fw-bold text-white font-25 pt-2 mb-0">4</p>
                                             </div>
                                             <div className="d-inline-flex ps-3">
                                                 <img src={playerIcon} style={{width: "43px"}}/>
                                                     <p className="text-white font-16 ps-3 pt-3 fw-semibold mb-0">Spirit
                                                         Points</p>
                                             </div>
                                             <div className="ms-auto">
                                                 <p className="font-oswald font-20 fw-bold pt-2 text-white mb-0">4521
                                                     PTS.</p>
                                             </div>
                                         </div>
                                     </div>
                                     <div className="row">
                                         <div className="d-flex flex-row py-2 border-bottom border-secondary">
                                             <div className="gray-background-image ps-4 pe-5">
                                                 <p className="fw-bold text-white font-25 pt-2 mb-0">5</p>
                                             </div>
                                             <div className="d-inline-flex ps-3">
                                                 <img src={player1} style={{width: "43px"}}/>
                                                     <p className="text-white font-16 ps-3 pt-3 fw-semibold mb-0">Spirit
                                                         Points</p>
                                             </div>
                                             <div className="ms-auto">
                                                 <p className="font-oswald font-20 fw-bold pt-2 text-white mb-0">3547
                                                     PTS.</p>
                                             </div>
                                         </div>
                                     </div>
                                     <div className="row">
                                         <div className="d-flex flex-row py-2 border-bottom border-secondary">
                                             <div className="gray-background-image ps-4 pe-5">
                                                 <p className="fw-bold text-white font-25 pt-2 mb-0">6</p>
                                             </div>
                                             <div className="d-inline-flex ps-3">
                                                 <img src={player2} style={{width: "43px"}}/>
                                                     <p className="text-white font-16 ps-3 pt-3 mb-0 fw-semibold">Spirit
                                                         Points</p>
                                             </div>
                                             <div className="ms-auto">
                                                 <p className="font-oswald font-20 fw-bold mb-0 pt-2 text-white">2578
                                                     PTS.</p>
                                             </div>
                                         </div>
                                     </div>
                                     <div className="row">
                                         <div className="d-flex flex-row py-2">
                                             <div className="gray-background-image ps-4 pe-5">
                                                 <p className="fw-bold text-white mb-0 font-25 pt-2">7</p>
                                             </div>
                                             <div className="d-inline-flex ps-3">
                                                 <img src={player3} style={{width: "43px"}}/>
                                                     <p className="text-white font-16 ps-3 pt-3 mb-0 fw-semibold">Spirit
                                                         Points</p>
                                             </div>
                                             <div className="ms-auto">
                                                 <p className="font-oswald font-20 fw-bold pt-2 mb-0 text-white">2456
                                                     PTS.</p>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                             </div>
                             {authUser && (
                                 <div className="row px-2 position-relative">
                                     <div className="col-lg-12 col-md-12 px-4 yellow-gradient">
                                         <div className="row">
                                             <div className="d-flex flex-row py-2">
                                                 <div className="yellow-background-image ps-4 pe-5">
                                                     <p className="fw-bold mb-0 font-25 pt-2">15</p>
                                                 </div>
                                                 <div className="d-inline-flex ps-3">
                                                     <img src={player3} style={{width: "43px"}}/>
                                                     <p className="text-white font-16 ps-3 pt-3 mb-0 fw-semibold">Spirit
                                                         Points</p>
                                                 </div>
                                                 <div className="ms-auto">
                                                     <p className="font-oswald font-20 fw-bold pt-2 mb-0 text-white">1236
                                                         PTS.</p>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                     <img src={arrowRight} style={{
                                         width: "45px",
                                         position: "absolute",
                                         bottom: "23.5%",
                                         left: "-2.3%"
                                     }}/>
                                 </div>
                             )}
                         </div>
                     </div>
                 </div>
             </div>
         </>
    );
}


export default WeeklyReward;
