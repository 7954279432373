import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';


// create slice

const name = 'friends';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const friendActions = { ...slice.actions, ...extraActions };
export const friendsReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        friends: [],
        unFriend: null,
        pendingRequestFriends: [],
        friendBlockList: [],
        removeBlockListFriendData: null,
        acceptFriendRequestData: null,
        declineFriendRequestData: null,
        error: null,
        friend: null,
        loading: false
    }
}

function createReducers() {
    return {
        resetFriends
    };

    function resetFriends(state) {
        state.friends = [];
        state.unFriend = null;
        state.pendingRequestFriends = [];
        state.friendBlockList = [];
        state.removeBlockListFriendData = null;
        state.acceptFriendRequestData = null;
        state.declineFriendRequestData = null;
        state.error = null;
        state.friend = null;
        state.loading = false;
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/friends`;
    const userURL = `${process.env.REACT_APP_API_URL}/user`;

    return {
        getFriendsList: getFriendsList(),
        addFriendByEmail: addFriendByEmail(),
        addToBlockList: addToBlockList(),
        removeFriend: removeFriend(),
        getPendingRequestFriendsList: getPendingRequestFriendsList(),
        acceptFriendRequest: acceptFriendRequest(),
        declineFriendRequest: declineFriendRequest(),
        getBlockFriendsList: getBlockFriendsList(),
        removeBlockListFriend: removeBlockListFriend(),
        muteUser: muteUser()
    };

    function getFriendsList() {
        return createAsyncThunk(
            `${name}/getFriendsAll`,
            async (data) => await fetchWrapper.post(`${baseUrl}/get_friend_list`, {...data.values})
        );
    }

    function addToBlockList() {
        return createAsyncThunk(
            `${name}/BlockFriend`,
            async (data) => await fetchWrapper.post(`${baseUrl}/add_to_block_list`, data)
        );
    }

    function muteUser() {
        return createAsyncThunk(
            `${name}/muteUser`,
            async (data) => await fetchWrapper.post(`${userURL}/chat/mute`, data)
        );
    }

    function addFriendByEmail() {
        return createAsyncThunk(
            `${name}addFriendByEmail`,
            async (data) => await fetchWrapper.post(`${baseUrl}/add_friend/email`, {profile: data.values.profile, email: data.values.email, appsId: data.values.appsId})
        );
    }

    function removeFriend() {
        return createAsyncThunk(
            `${name}/remove_friend`,
            async (data) => await fetchWrapper.post(`${baseUrl}/remove_friend`, {profile: data.values.profile, friend_id: data.values.friend_id, appsId: data.values.appsId})
        )
    }

    function getPendingRequestFriendsList() {
        return createAsyncThunk(
            `${name}/getPendingRequestFriendsList`,
            async (data) => await fetchWrapper.post(`${baseUrl}/getFriendRequestList`, {profile: data.values.profile, appsId: data.values.appsId})
        )
    }

    function acceptFriendRequest() {
        return createAsyncThunk(
            `${name}/acceptFriendRequest`,
            async (data) => await fetchWrapper.post(`${baseUrl}/acceptFriendRequest`,{profile: data.values.profile, friend_id: data.values.friend_id, appsId: data.values.appsId})
        )
    }

    function declineFriendRequest() {
        return createAsyncThunk(
            `${name}/declineFriendRequest`,
            async (data) => await fetchWrapper.post(`${baseUrl}/declineFriendRequest`, {profile: data.values.profile, friend_id: data.values.friend_id, appsId: data.values.appsId})
        )
    }

    function getBlockFriendsList() {
        return createAsyncThunk(
            `${name}/getBlockFriendsList`,
            async (data) => await fetchWrapper.post(`${baseUrl}/get_block_list`, {...data.values})
        )
    }

    function removeBlockListFriend() {
        return createAsyncThunk(
            `${name}/removeBlockListFriend`,
            async (data) => await fetchWrapper.post(`${baseUrl}/remove_from_block_list`, {profile: data.values.profile, block_user_id: data.values.block_user_id, appsId: data.values.appsId})
        )
    }

}

function createExtraReducers() {
    return {
        ...getFriendsList(),
        ...addFriendByEmail(),
        ...removeFriend(),
        ...getPendingRequestFriendsList(),
        ...acceptFriendRequest(),
        ...declineFriendRequest(),
        ...getBlockFriendsList(),
        ...removeBlockListFriend(),
        ...muteUser(),
    };

    function getFriendsList() {
        var { pending, fulfilled, rejected } = extraActions.getFriendsList;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.friends = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }

    function muteUser() {
        var { pending, fulfilled, rejected } = extraActions.muteUser;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }

    function addFriendByEmail() {
        var { pending, fulfilled, rejected } = extraActions.addFriendByEmail;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.friend = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }

    function removeFriend() {
        var { pending, fulfilled, rejected } = extraActions.removeFriend;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.unFriend = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }

    function getPendingRequestFriendsList() {
        var { pending, fulfilled, rejected } = extraActions.getPendingRequestFriendsList;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.pendingRequestFriends = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }

    function acceptFriendRequest() {
        var { pending, fulfilled, rejected } = extraActions.acceptFriendRequest;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.acceptFriendRequestData = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }

    function declineFriendRequest() {
        var { pending, fulfilled, rejected } = extraActions.declineFriendRequest;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.declineFriendRequestData = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }

    function getBlockFriendsList() {
        var { pending, fulfilled, rejected } = extraActions.getBlockFriendsList;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.friendBlockList = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }

    function removeBlockListFriend() {
        var { pending, fulfilled, rejected } = extraActions.removeBlockListFriend;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.removeBlockListFriendData = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }
}
