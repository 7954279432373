import React from 'react';

import alertSharp from "../../assets/images/icon/alert-sharp-svg.svg";
import alertRound from "../../assets/images/icon/alert-round-svg.svg";
import closebtn from "../../assets/images/desclaimer-close-icon.svg"
import  "../../assets/css/discalamier-model.css"

import * as Yup from "yup";
import {Modal} from "react-bootstrap";



export default function DisclamierModel({isOpen, handleClose, submit}) {

    return (
        <Modal
            show={isOpen}
            onHide={handleClose}
            centered
            className={'main-desclaimer-model'}
        >
            <Modal.Header className="row mx-0 p-0 border-0 shadow-none">
                <div className={'p-0 position-relative disclamier-header'}>
                    <button className={'disclamier-close-button border-0 p-0'} onClick={handleClose}>
                        <img  src={closebtn}/>
                    </button>
                </div>
            </Modal.Header>
            <div className={'discalmier-model'}>
                <div className={'d-flex flex-column align-items-center gap-2'}>
                    <img src={alertRound} alt="" style={{width: "80px"}}/>
                    <h4 className="text-white fw-bold ms-2 mb-4">Disclamier</h4>
                </div>
                <div>
                    <div className={'d-flex align-items-center'}>
                        <img src={alertSharp} style={{width: '23px'}} className={"me-2"}/>
                        <p className={'text-white disclamier-note mb-0'}>You need to be over 18 to withdraw funds.</p>
                    </div>
                    <div className={'d-flex align-items-center'}>
                        <img src={alertSharp} style={{width: '23px'}} className={"me-2"}/>
                        <p className={'text-white disclamier-note mb-0'}>Complete KYC 1 to earn referral bonus.</p>
                    </div>
                    <div className={'d-flex align-items-center'}>
                        <img src={alertSharp} style={{width: '23px'}} className={"me-2"}/>
                        <p className={'text-white disclamier-note mb-0'}>Valid Mobile and Government ID is must.</p>
                    </div>
                </div>
                <div className={'d-flex justify-content-center'}>
                    <button className={' my-3 buttons-options w-100 eighteen-plus-yes'} onClick={submit}>I am over 18, Continue</button>
                </div>
                <div className={'d-flex justify-content-center '}>
                    <button className={'buttons-options w-100 eighteen-plus-no'} onClick={handleClose}>No I am Not</button>
                </div>
            </div>
        </Modal>
    );

}
