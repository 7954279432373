import React, {useEffect, useMemo, useState} from 'react';
import dotIcon from "../assets/images/icon/dot.svg";
import sendIcon from "../assets/images/icon/icon-send.svg";
import {useNavigate, useParams} from "react-router-dom";
import {addDoc, collection, onSnapshot, query, serverTimestamp} from "firebase/firestore";
import {useSelector} from "react-redux";
import {db} from "../../firebase-config";
import _ from "lodash";
import {timeCalculate} from "../../_helpers";

const GameRoomChat = () => {
    const {gameId, id} = useParams();
    const authUser = useSelector(x => x.auth.user);
    const [message, setMessage] = useState('');
    const [messagesLists, setMessages] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const roomInfo = collection(db, "privateRoomPlayers");
        const queryMessages = query(roomInfo);
        const unsubscribe = onSnapshot(queryMessages, (snapshot) => {
            let messages = []
            snapshot.forEach((doc) => {
                if (doc.data().gameId === id) {
                    messages.push({...doc.data()});
                }
            })
            const sortedData = _.sortBy(messages, 'createdAt');
            setMessages([...sortedData])

            scrollToBottom(200);
        })
        return () => {
            unsubscribe();
            setMessages([]);
        }
    }, [window.location.pathname]);

    const sendMessage = async (event) => {
        event.preventDefault();
        if (message && id) {
            const roomInfo = collection(db, "privateRoomPlayers");
            const currentDate = serverTimestamp();
            const user = _.pick(authUser, ["first_name", "last_name", "user_id", "email", "profile_picture"]);
            setMessage("");
            await addDoc(roomInfo, {
                message,
                user,
                room: gameId,
                gameId: id,
                createdAt: currentDate
            }).then(() => scrollToBottom(100))
        }
    }
    const scrollToBottom = (timeout = 200) => {
        setTimeout(() => {
            document.querySelector(".bottom-line")?.scrollIntoView({behavior: "auto"});
        }, timeout)
    }

    const renderMessagesLists = useMemo(() => {
        let lists = []
        messagesLists.forEach((item) => {
            const isCurrentUser = _.get(item, "user.user_id") === _.get(authUser, "user_id")
            lists.push(
                <div
                    key={item.id}
                    className="player-out-msg"
                >
                    <div className="msg-out-time d-flex gap-1">
                        <span className="text-secondary">
                            {_.get(item, "user.first_name","")} {_.get(item, "user.last_name","")}
                        </span>
                        <span>
                            <img src={dotIcon} alt="dotIcon" style={{width: "5px"}}/>
                        </span>
                        <span>
                            {item.createdAt ? timeCalculate(item.createdAt.toDate()) : ""}
                        </span>
                    </div>
                    <div className={`chat-msg-color w-100 chatmsg-width ${isCurrentUser ? "message-in":"message-out"}`}>
                        <div className="out-msgs">
                            <div>
                                <div className="game-player-chat-msg">
                                    <span className="selectable-text">
                                        {item.message}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return lists;
    },[messagesLists])

    return (
        <div className="sidebar-chat player-chat-body player-chat-games">
            <div className="player-chat-content">
                <header className="chat-heading background-body border-bottom border-secondary">
                    <h2 className="color-green font-22 font-oval-single mb-0">Chat</h2>
                </header>
                <div className="players-chat-body background-body">
                    <div className="players-chat-scroll h-100">
                        <div className="game-player-chat" tabIndex="0">
                            <div className="players-chat">
                                {renderMessagesLists}
                                <div className="bottom-line"/>
                            </div>
                        </div>
                    </div>
                </div>
                <footer tabIndex="2" className="chat-footer">
                    {authUser && (
                        <form className="row" onSubmit={sendMessage}>
                            <div className="chat-footer-scroll players-chat-scroll">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Type here..."
                                    className="game-message-input text-white"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                                <div className="send-msg d-flex align-items-center">
                                    <button type="submit" className="btn btn-transparent p-0">
                                        <img className="w-auto" src={sendIcon} alt="send icon"/>
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                    <div
                        className="row background-dark-grey-shade3 border-top border-secondary py-2">
                        <div className="d-flex flex-row py-1">
                            <div className="px-3 ms-auto">
                                <button type="button"
                                        className="btn border-0 bg-warning font-13 fw-bold py-2 btn-hover color-2 px-4">
                                    Offer Draw
                                </button>
                            </div>
                            <div className="pe-3">
                                <button
                                    type="button"
                                    className="btn font-13 fw-bold py-2 text-white text-center btn-hover color-3 px-3"
                                    onClick={() => navigate("/")}
                                >Leave Game
                                </button>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default GameRoomChat;