import React from "react";
import { Routes, Route, Navigate } from 'react-router-dom';
import SidebarMenu from "./sidebar/SidebarMenu";
import NavbarMenu from "./navbar/NavbarMenu";

import Home from "../../pages/home";
import Friends from "../../pages/friends";
import Chat from "../../pages/chat";
import Refer from "../../pages/refer_earn";
import Shop from "../../pages/shop";
import VirtuaSwap from "../../pages/virtua_swap";
import WeeklyReward from "../../pages/weekly-reward";
import Play from "../../pages/play";
import Game from "../../pages/game";
import GameInfo from "../../pages/game_info";
import Errors from "../../pages/errors";
import Favourite from "../../pages/favourite";
import BotChessGame from "../../pages/game/BotChessGame/BotChessGame";

function Layout() {

    return (
        <div className="row">
            {/*<BrowserRouter>*/}
                <SidebarMenu />
                <div className="col-lg-11 col-md-11 bodyPart">
                    <NavbarMenu/>
                    <Routes>
                         <Route exact path="/" element={<Home/>} />
                         <Route path="/home" element={<Home/>} />
                         <Route path="/friends" element={<Friends/>} />
                         <Route path="/chat" element={<Chat/>} />
                         <Route path="/chat/:id" element={<Chat/>} />
                         <Route path="/refer_earn" element={<Refer/>} />
                         <Route path="/shop" element={<Shop/>} />
                         <Route path="/virtua_swap" element={<VirtuaSwap/>} />
                         <Route path="/weekly_reward" element={<WeeklyReward/>} />
                         <Route path="/play" element={<Play/>} />
                         <Route path="/game/:gameId/:id" element={<Game/>} />
                         <Route path="/game_info/:id" element={<GameInfo/>} />
                         <Route path="/favorite" element={<Favourite/>} />
                        <Route path="/boatchess" element={<BotChessGame/>} />
                         <Route path='/error/404' element={<Errors/>} />
                        <Route path="*" element={ <Navigate to="/error/404" replace />} />
                    </Routes>
                </div>
            {/*</BrowserRouter>*/}
        </div>

    );
}

export default Layout;
