import React, {useEffect, useMemo, useState} from "react";
import {Col} from "react-bootstrap";
import {useSelector} from "react-redux";
import _ from "lodash";
import {useNavigate, useParams} from "react-router-dom";

export default function ChatSidebar() {
    const {id} = useParams()
    const navigate = useNavigate()
    const applicationList = useSelector((state) => state.application.applicationItem);
    const [applications,setApplications] = useState(applicationList);

    useEffect(() => {
        setApplications(applicationList);
    }, [applicationList]);

    return (
        <>
            <Col xs={12} md={4} className="sidebar-chat sidebar-chat-left">
                <div className="sidebar-chat-content">
                    <header className="chat-heading">
                        <h2 className="mb-0">Chat</h2>
                    </header>
                    <div tabIndex="-1">
                        <div className="search_bar mb-2">
                            <div className="input-group align-items-center">
                                <input
                                    type="text" className="form-control rounded text-white shadow-none chat-search"
                                    placeholder="Search"
                                    onChange={(event)=>{
                                        let lists = applicationList.filter((app)=> app?.app_name?.toLowerCase().includes(event.target.value.toLowerCase()));
                                        setApplications(lists);
                                    }}
                                />
                                <i className="fa-solid fa-magnifying-glass text-white"></i>
                            </div>
                        </div>
                    </div>
                    <div className="chat-list">
                        <div tabIndex="-1" data-tab="3">
                            <div style={{pointerEvents: 'auto'}}>
                                <div className="chat-scroll">
                                    {applications.map((application,index)=> {
                                        const activeClass = (Number(id) === application.id) ? " _1f1zm" : "";
                                        return _.get(application, "app_icon") && (
                                            <div
                                                className="chat-player"
                                                style={{zIndex: 39, height: '72px', transition: 'none 0s ease 0s'}}
                                                key={index}
                                            >
                                                <div
                                                    className={`chat-player-content${activeClass}`}
                                                    onClick={() => {
                                                        navigate(`/chat/${application.id}`)
                                                    }}
                                                >
                                                    <div className="player-profile">
                                                        <div className="player-pic"
                                                             style={{height: '49px', width: '49px'}}>
                                                            <img
                                                                src={"https://virtuagrid.com/" + _.get(application, "app_icon")}
                                                                alt=""/>
                                                        </div>
                                                    </div>
                                                    <div className="player-msg">
                                                        <div className="player-last-msg">
                                                            <div className="player-last-msg-show">
                                    <span title="Bruce Wayne" className="player-name">
                                        {_.get(application, "app_name", "")}
                                    </span>
                                                            </div>
                                                            <div className="playerchat-time"><span
                                                                className="chat-time">5:37 PM</span>
                                                            </div>
                                                        </div>
                                                        <div className="player-msg-show">
                                                            <div className="player-messages">
                                    <span className="_2_LEW">
                                        <span className="player-name chat-msg">
                                            {_.get(application, "org_group_name", "")}
                                        </span>
                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </>
    )
}
