import React, {useEffect, useState} from 'react';
import statusIcon from "../../assets/images/icon/NotificationIcon.svg";
import {Link} from "react-router-dom";
import group87 from "../../assets/images/icon/Group 87.svg";
import metroCrossIcon from "../../assets/images/icon/Icon metro-cross.svg";
import smiley from "../../assets/images/icon/smiely.svg";
import awesomeCheckIcon from "../../assets/images/icon/Icon awesome-check.svg";
import {useDispatch, useSelector} from "react-redux";
import {authActions, userActions} from "../../../_store";
import {toast} from "react-toastify";
import _ from "lodash";


const userStatus = [
    {
        title: "auto",
        label: "online",
        Icon: <img src={group87} alt="logo" className="pe-2" style={{width: '22px'}}/>
    },
    {
        title: "offline",
        label: "offline",
        Icon: <i className="fa-solid fa-circle font-10 py-1 text-secondary me-1"></i>
    },
    {
        title: "don't show",
        label: "don't show",
        Icon: <img src={metroCrossIcon} alt="logo" className="pe-2" style={{width: '18px'}}/>
    }
]
const UserStatusBar = () => {
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const [statusMessage, setStatusMessage] = useState("");

    useEffect(() => {
        setStatusMessage(_.get(authUser, "status_message"));
    }, [_.get(authUser, "status_message")])

    const changeStatus = (title = "") => {
        if (title === "") {
            title = _.get(authUser, "status");
        }
        const values = {
            user_id: authUser.user_id,
            status: title,
            status_message: statusMessage,
        }
        return dispatch(userActions.postStatus({values}))
            .then((response) => {
                if (!response?.error && !response.payload?.error) {
                    toast.success(response.payload.message);
                    const values = authUser && authUser.user_id;
                    dispatch(authActions.getUserDetails(values))
                }
                if (response?.error) {
                    toast.error(response?.error.message);
                }
            })
            .catch((error) => {
                console.error(error);
            })
    }

    const active = _.find(userStatus, {title: _.get(authUser, "status")});
    return (
        <div className="d-flex flex-row">
            {_.get(active,"Icon")}
            <span
                className="text-secondary font-10" id="statusDropdown"
                role="button" data-bs-toggle="dropdown"
            >  {_.get(active,"label")}
            </span>
            <ul className="dropdown-menu status-dropdown py-4">
                <div className="row">
                    <p>
                        <img src={statusIcon} alt="logo" className="" style={{width: '3px'}}/>
                        <span className="ps-4 fw-bold yellow-heading font-13">status</span>
                    </p>
                </div>
                <div className="row px-4">
                    {userStatus.map((item, index) => {
                        let classNames = "btn font-13 text-white fw-bold py-2 btn-outline-secondary w-100 text-capitalize";
                        if (_.get(authUser, "status") === _.get(item,"title")) {
                            classNames += " grey-button";
                        }
                        return (
                            <div className="col-lg-4 col-md-4 px-1" key={index}>
                                <button
                                    className={classNames}
                                    onClick={() => changeStatus(_.get(item,"title"))}
                                >
                                    {_.get(item,"Icon")}
                                    {_.get(item,"title")}
                                </button>
                            </div>
                        )
                    })}
                </div>
                <div>
                    <div className="row mt-4">
                        <p>
                            <img src={statusIcon} alt="logo" style={{width: '3px'}}/>
                            <span className="ps-4 fw-bold yellow-heading font-13">status Message</span>
                        </p>
                    </div>
                    <form
                        className="row m-0"
                        onClick={(e) => e.stopPropagation()}
                        onSubmit={(event) => {
                            event.preventDefault();
                            changeStatus();
                        }}
                    >
                        <div className="col-md-1 pe-0">
                            <div className="btn px-2 py-1 grey-button">
                                <img src={smiley} alt="logo" style={{width: '15px'}}/>
                            </div>
                        </div>
                        <div className="col-md-11">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control border-end-0 border-secondary bg-transparent text-white"
                                    placeholder="I'm playing Tuti Games!"
                                    value={statusMessage}
                                    onChange={(event) => setStatusMessage(event.target.value)}
                                />
                                <span className="input-group-text bg-transparent border-start-0 border-secondary">
                                    <img
                                        src={awesomeCheckIcon}
                                        alt="logo" className="pe-2 cursor-pointer"
                                        style={{width: '20px'}}
                                        onClick={() => changeStatus()}
                                    />
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
            </ul>
        </div>
    );
};

export default UserStatusBar;