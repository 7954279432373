import {store} from '../_store';
import Cookies from "js-cookie";

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE')
};

function request(method) {
    return (url, body) => {
        const requestOptions = {
            method,
            headers: authHeader(url)
        };
        if (body) {
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(body);
        }
        return fetch(url, requestOptions).then(handleResponse);
    }
}

// helper functions

function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url
    const token = authToken();
    const customHeader = authCustomHeader();
    const isLoggedIn = !!token;
    const isApiUrl = url.startsWith(process.env.REACT_APP_API_URL);
    if (url.startsWith(process.env.REACT_APP_VIRTUACOINS_URL)) {
        return {
            "Authorization":`Bearer ${token}`
        }
    }
    if (isLoggedIn && isApiUrl) {
        return {
            'X-Authorization': `${token}`,
            'authid': `${customHeader.authid}`,
            'mainid': `${customHeader.mainid}`,
            'accountid': `${customHeader.accountid}`,
            'salt': `${customHeader.salt}`,
            'valueverifier': `${customHeader.valueverifier}`,
            'appid': 'FLF2KVSBG72K6O'
        };
    } else {
        return {};
    }
}

function authToken() {
    return Cookies.get("jwt");
}
function authCustomHeader() {
    return store.getState().auth.user?.plain_obj;
}

function handleResponse(response) {
    //return response;
    return response.text().then(text => {
        const data = text && JSON.parse(text);
         // console.log(data);
         if (data.error) {
             const error = data || response.statusText;
             // console.log('error'+ error)
             return Promise.reject(error);
         }
        // if (!response.ok) {
        //     if ([401, 403].includes(response.status) && authToken()) {
        //         // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        //         const logout = () => store.dispatch(authActions.logout());
        //         logout();
        //     }
        //
        //     const error = (data && data.message) || response.statusText;
        //     return Promise.reject(error);
        // }

        return data;
    });
}
