import React, {useEffect, useState} from 'react';
import _ from "lodash";
import MessageContent from "../MessageContent";
import gameControllerIcon from "../assets/images/icon/icon-game-controller.svg";
import sendIcon from "../assets/images/icon/icon-send.svg";
import {Col} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {addDoc, onSnapshot, query, serverTimestamp, collection} from "firebase/firestore";
import {db} from "../../firebase-config";
import {useParams} from "react-router-dom";
import {applicationActions} from "../../_store";

const ChatBoxContainer = ({setSelectGameModal}) => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const authUser = useSelector(x => x.auth.user);
    const applicationInfo = useSelector( (x) => x.application.applicationInfo);
    const [messagesLists, setMessages] = useState([]);
    const [message, setMessage] = useState('');

    useEffect(()=>{
        dispatch(applicationActions.getApplicationDetails({id}));
    },[window.location.pathname])

    useEffect(() => {
        const roomInfo = collection(db, window.location.pathname.split("/").pop());
        const queryMessages = query(roomInfo);
        const unsubscribe = onSnapshot(queryMessages, (snapshot) => {
            let messages = []
            snapshot.forEach((doc) =>  {
                if (doc.data().room === window.location.pathname.split("/").pop()) {
                    messages.push({...doc.data()});
                }
            })
            const sortedData = _.sortBy(messages, 'createdAt');
            setMessages([...sortedData])

            scrollToBottom(200)
        })
        return () => {
            unsubscribe();
            setMessages([]);
        }
    }, [window.location.pathname]);

    const scrollToBottom = (timeout = 200) => {
        setTimeout(()=>{
            document.querySelector(".bottom-line")?.scrollIntoView({behavior:"auto"});
        },timeout)
    }

    const sendMessage = async (event) => {
        event.preventDefault();
        if (message && id) {
            const roomInfo = collection(db, String(id));
            const currentDate = serverTimestamp();
            const user = _.pick(authUser,["first_name","last_name","user_id","email","profile_picture"]);
            setMessage("");
            await addDoc(roomInfo, { message, user, room: id , createdAt:currentDate }).then(()=> scrollToBottom(100))
        }
    }
    const active = applicationInfo;
    return (
        <Col xs={12} md={8} className="sidebar-chat player-chat-body">
            <div className="player-chat-content">
                <header className="player-chat-header">
                    <div className="player-chat-profile" role="button">
                        <div className="player-pic" style={{height: '40px', width: '40px'}}>
                            <img src={"https://virtuagrid.com/" + _.get(active, "app_icon")} alt=""/>
                        </div>
                    </div>
                    <div className="player-name-header" role="button">
                        <div className="player-name-chat-body">
                            <span className="player-name">
                                {_.get(active,"app_name")}
                            </span>
                        </div>
                        <div className="player-last-seen player-last-seen-cotent">
                            <span className="game-player-title">
                                {_.get(active,"developer_comment")}
                            </span>
                        </div>
                    </div>
                    <div className="char-header-menu">
                        <a href="#"><i className="fa-solid fa-ellipsis"></i></a>
                    </div>
                </header>
                <div className="players-chat-body">
                    <div className="players-chat-scroll h-100">
                        <div className="game-player-chat" tabIndex="0">
                            <div className="players-chat">
                                {(_.isEmpty(messagesLists)) && <h4 className="text-white text-center">No data available</h4>}
                                {messagesLists.map((message, index) => (
                                    <MessageContent
                                        key={index}
                                        message={message}
                                        setSelectGameModal={setSelectGameModal}
                                    />
                                ))}
                                <div className="bottom-line"/>
                            </div>
                        </div>
                    </div>
                </div>
                {authUser && (
                    <footer tabIndex="2" className="chat-footer">
                        <form
                            onSubmit={sendMessage}
                            className="chat-footer-scroll players-chat-scroll"
                        >
                            <input
                                type="text"
                                name="name"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                className="message_input"
                                placeholder="Type here..."
                            />
                            <div className="send-msg d-flex align-items-center">
                                <button type="button" className="btn btn-transparent"
                                        onClick={() => setSelectGameModal(true)}>
                                    <img className="w-auto me-3" src={gameControllerIcon}
                                         alt="game"/>
                                </button>
                                <button type="submit" className="btn btn-transparent">
                                    <img className="w-auto" src={sendIcon} alt="send"/>
                                </button>
                            </div>
                        </form>
                    </footer>
                )}
            </div>
        </Col>
    );
};

export default ChatBoxContainer;