import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {applicationActions} from "../../_store";

import ChatSidebar from "../../components/chat/ChatSidebar";
import SelectGameModal from "../../components/layout/modal/SelectGameModal";
import {Row} from "react-bootstrap";
import ChatBoxContainer from "../../components/ChatBoxContainer";
import {useParams} from "react-router-dom";

function Chat() {
    const {id} = useParams();
    const dispatch = useDispatch();
    const [selectGameModal, setSelectGameModal] = useState(false);

    useEffect(() => {
        dispatch(applicationActions.getApplicationList());
    }, []);

    const closeSelectGameModal = () => {
        setSelectGameModal(false);
    }

    return (
        <>
            <div className="row">
                <div id="app">
                    <div className="chat-body">
                        <Row className="chat-content two m-0">
                            <ChatSidebar />
                            {id && <ChatBoxContainer setSelectGameModal={setSelectGameModal}/>}
                        </Row>
                    </div>
                </div>
            </div>
            <SelectGameModal isOpen={selectGameModal} handleClose={closeSelectGameModal}></SelectGameModal>
        </>
    );
}


export default Chat;
