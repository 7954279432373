import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {authActions, profileActions} from "../../../_store";
import { toast } from 'react-toastify';
import RegisterProfileModal from "../modal/RegisterProfileModal";
import ProfileAuthPinModal from "../modal/ProfileAuthPinModal";
import userIconImg from "../../assets/images/icon/Icon awesome-user-alt.svg";
import profileImg from "../../assets/images/icon/profile.svg";
import {Modal} from "react-bootstrap";
import Cookies from "js-cookie";

export default function ProfileModal({isOpen, handleClose}) {
    const authProfile = useSelector(x => x.auth.user);
    const [activeProfile, setActiveProfile] = useState();
    const [registerProfileModal, setRegisterProfileModal] = useState(false);
    const [profileAuthPinModal, setProfileAuthPinModal] = useState(false);
    const [selectedProfile, setSelectedProfile] = useState(null);

    const myStyle = {
        backgroundImage: `url('../../assets/images/icon/add-profile.svg')`
    };

    // Redux Methods
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const loading = useSelector(x => x.profiles.loading);
    const profileChangeError = useSelector(x => x.profiles.error);
    const userProfileData = useSelector(x => x.profiles.userProfileData);

    useEffect(() => {
        if (authUser && isOpen){
            const values = authUser && authUser.user_id;
            dispatch(profileActions.getUserProfiles(values))
        }
    }, [dispatch,isOpen]);

    useEffect(() => {
        setActiveProfile(authProfile?.profile_id);
    }, [authProfile, isOpen]);

    const toggleClass = (item) => setActiveProfile(item);

    const createProfile = () => {
       handleClose();
       setRegisterProfileModal(true);
    }

    const goProfile = () => {
        const values = {
            user_id: authUser.user_id,
            account_id: authUser.account_id,
            email: authUser.email,
            user_profile_id: selectedProfile.profile_id,
            first_name: selectedProfile.first_name,
            last_name: selectedProfile.last_name,
            auth_token: Cookies.get("jwt")
        }
        dispatch(authActions.switchProfile({values})).then((response) => {
            if (!response?.error && !response.payload?.error) {
                dispatch(authActions.getProfile({profile_id:selectedProfile.profile_id}));
            }
            if (response?.error) {
                toast.error(response?.error.message);
            }
            return handleClose(true);
        })
    }
    const closeRegisterProfileModal = () => {
        setRegisterProfileModal(false);
    }
    const closeProfileAuthPinModal = () => {
        setProfileAuthPinModal(false);
        return handleClose(false);
    }

    const backRegisterProfileModal = (val) => {
        if (val) {
            setRegisterProfileModal(false);
            return handleClozse(false);
        } else {
            setRegisterProfileModal(false);
            return handleClose(true);
        }
    }

    return (
        <>
            <Modal
                show={isOpen}
                onHide={handleClose}
                size="md"
                centered
            >
                <Modal.Header closeButton className="row mx-0 border-0 shadow-none">
                    <div className="d-flex align-items-center header_vcoin">
                        <img src={userIconImg} alt="" className="pe-2" style={{width: '30px'}}/>&nbsp;&nbsp;
                        <h4 className="modal-title text-white">Select Profile</h4>
                    </div>
                </Modal.Header>
                <Modal.Body className="text-white">
                    <div className="modal-body mx-0">
                        <form>
                            <div className="seed-phrase">
                                <h6>Seed Phrase</h6>
                                <div className="row">
                                    <div className="col-md-3 my-2" onClick={() => toggleClass(0)}>
                                        <div className={"image-profile rounded " + (activeProfile === 0 ? 'active' : '')}>
                                            <input type="radio" id={`ANSI0`} name="check-substitution-2"/>
                                            <label
                                                className="btn d-flex justify-content-center p-0 rounded-circle"
                                                htmlFor="fileToUpload">
                                                <div className="profile-pic" style={myStyle}>
                                                    <span className="check-icon"></span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    {(userProfileData || []).map((item, index) => (
                                        <div
                                            key={index}
                                            className="col-md-3 my-2"
                                            onClick={() => {
                                                toggleClass(item.profile_id);
                                                setSelectedProfile(item);
                                            }}
                                        >
                                            <div
                                                className={"image-profile rounded " + (activeProfile === item.profile_id ? 'active' : '')}>
                                                <label className="btn d-flex justify-content-center p-0 rounded-circle">
                                                    <img src={item.profile_picture} alt=""/>
                                                    <span className="check-icon"></span>
                                                </label>
                                            </div>
                                            <div className="text-light text-center">
                                                {(item.first_name || "") + " " + (item.last_name || "")}
                                            </div>
                                        </div>
                                    ))}
                                    <div className="col-md-12">
                                        {profileChangeError && <p className="text-center text-wrap text-danger mt-3 mb-0">{profileChangeError.message}</p>}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer border-0 mx-0">
                        <div className="row w-100">
                            <div className="col-md-6 my-2">
                                {activeProfile === 0
                                    ? <button className="login-btn btn-hover color-1" onClick={createProfile}>Next</button>
                                    : (
                                        <button
                                            className="login-btn btn-hover color-1" onClick={goProfile}
                                        >
                                            {loading ? (
                                                <div className="spinner-border spinner-border-sm text-warning"
                                                     role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            ) : 'Next'}
                                        </button>
                                    )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <RegisterProfileModal isOpen={registerProfileModal} handleClose={closeRegisterProfileModal}
                                  handleBack={backRegisterProfileModal}/>
            <ProfileAuthPinModal isOpen={profileAuthPinModal} handleClose={closeProfileAuthPinModal}/>
        </>
    );
}
