import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';
import {toast} from "react-toastify";


// create slice

const name = 'referEarn';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const referEarnActions = { ...slice.actions, ...extraActions };
export const referEarnReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        referral: null,
        referralDetails: null,
        referralInviteEmail: null,
        referralInvitePhone: null,
        error: null,
        loading: false,
        referralDetailsLoading: false,
    }
}

function createReducers() {
    return {
        resetReferEarn
    };

    function resetReferEarn(state) {
        state.referral = null;
        state.referralDetails = null;
        state.referralInviteEmail = null;
        state.referralInvitePhone = null;
        state.error = null;
        state.loading = false;
        state.referralDetailsLoading = false;
    }
}

function createExtraActions() {
    const referralUrl = `${process.env.REACT_APP_API_URL}/referral`;
    const inviteUrl = `${process.env.REACT_APP_API_URL}/invite_friends`;

    return {
        createReferral: createReferral(),
        getReferralDetails: getReferralDetails(),
        inviteReferralByEmail: inviteReferralByEmail(),
        inviteReferralByPhone:inviteReferralByPhone()
    };

    function createReferral() {
        return createAsyncThunk(
            `${name}/createReferral`,
            async (data) => await fetchWrapper.post(`${referralUrl}/create`, { user_id: data.values.user_id, referral: data.values.referral }))
    }

    function getReferralDetails() {
        return createAsyncThunk(
            `${name}/getReferralDetails`,
            async (data) => await fetchWrapper.post(`${referralUrl}/details`, {user_id: data})
        )
    }

    function inviteReferralByEmail() {
        return createAsyncThunk(
            `${name}/inviteReferralByEmail`,
            async (data) => await fetchWrapper.post(`${inviteUrl}`, {user_id:data.values.user_id,user_email:data.values.user_email,invitee_email: data.values.email, referral_code: data.values.referral_code, user_name: data.values.user_name, link: data.values.link})
        )
    }
    function inviteReferralByPhone() {
        return createAsyncThunk(
            `${name}/inviteReferralByPhone`,
            async (data) => await fetchWrapper.post(`${inviteUrl}/phone`, data.values)
        )
    }
}

function createExtraReducers() {
    return {
        ...createReferral(),
        ...getReferralDetails(),
        ...inviteReferralByEmail(),
        ...inviteReferralByPhone()
    };

    function createReferral() {
        var { pending, fulfilled, rejected } = extraActions.createReferral;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.referral = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }

    function getReferralDetails() {
        var { pending, fulfilled, rejected } = extraActions.getReferralDetails;
        return {
            [pending]: (state) => {
                state.referralDetailsLoading = true;
            },
            [fulfilled]: (state, action) => {
                state.referralDetails = action.payload.data;
                state.referralDetailsLoading = false;
            },
            [rejected]: (state, action) => {
                state.referralDetailsLoading = false;
            }
        };
    }

    function inviteReferralByEmail() {
        var { pending, fulfilled, rejected } = extraActions.inviteReferralByEmail;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                toast.success(action.payload.message);
                state.referralInviteEmail = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                toast.success(action.error.message);
                state.loading = false;
            }
        };
    }
    function inviteReferralByPhone() {
        var { pending, fulfilled, rejected } = extraActions.inviteReferralByPhone;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.referralInvitePhone = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }
}
