import React, {Component} from "react";
import WithMoveValidation from "../integrations/WithMoveValidation";

class ChessComponent extends Component {
    render() {
        return (
            <div style={boardsContainer}>
                <WithMoveValidation/>
            </div>
        );
    }
}

export default ChessComponent;

const boardsContainer = {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
};
const boardStyle = {
    borderRadius: "5px",
    boxShadow: `0 5px 15px rgba(0, 0, 0, 0.5)`
};
