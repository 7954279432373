import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {authActions, profileActions} from "../../../_store";
import {ErrorMessage, Field, Form, Formik} from 'formik';
import groupImg from "../../assets/images/icon/Group 2344.svg"
import svgRegisterImg from "../../assets/images/icon/register-right-arrow.svg"
import svgLoginImg from "../../assets/images/icon/login-grey.svg"
import personImg from "../../assets/images/icon/Icon ionic-ios-person-add.svg"
import * as Yup from "yup";
import {Modal} from "react-bootstrap";

export default function RegisterProfileModal({isOpen, handleClose, handleBack}) {
    const formRef = useRef();
    const dispatch = useDispatch();
    const avatarLists = useSelector(x => x.auth.avatarLists);
    const authUser = useSelector(x => x.auth.user);
    const [activeAvatar, setActiveAvatar] = useState(1);

    useEffect(()=> {
        if (isOpen){
            dispatch(authActions.getUserAvatars())
        }
    },[isOpen])

    const toggleClass = (item) => setActiveAvatar(item.id);

    const goBackProfile = () => handleBack(true)

    async function _saveProfile(values) {
        const data = {...values}
        data["profile_image_id"] = activeAvatar;

        return dispatch(profileActions.newProfile(data))
                .then((response) => {
                    const values = authUser && authUser.user_id;
                    dispatch(profileActions.getUserProfiles(values))
                    formRef?.current?.resetForm();
                    handleClose()
                }).catch((err)=>{
                console.log(err)
            })
    }
    function renderMenuItems() {
        let groupSize = 5;
        return avatarLists.map(function (item, index) {
            return (
                <div
                    key={index}
                    onClick={() => toggleClass(item)}
                    className={"col-md-2 avatar-profile rounded " + (activeAvatar === item.id ? 'active' : '')}
                >
                    <input type="radio" id={item.id} name="check-substitution-2"/>
                    <label
                        className="btn d-flex justify-content-center p-0 rounded-circle" htmlFor="ANSI"
                        style={{width: '100%', height: '80px'}}
                    >
                        <img src={item.image} alt="" />
                        <span className="check-icon"/>
                    </label>
                </div>
            );
        }).reduce(function (r, element, index) {
            index % groupSize === 0 && r.push([]);
            r[r.length - 1].push(element);
            return r;
        }, []).map(function (rowContent, index) {
            return (
                <div
                    key={index} className="row"
                    style={{paddingLeft: '5px', paddingRight: '5px'}}
                > {rowContent}
                </div>
            );
        });
    }

    return (
        <Modal
            show={isOpen}
            onHide={handleClose}
            size="md"
            centered
        >
        <Modal.Header closeButton className="row mx-0 border-0 shadow-none">
                <div className="col-md-6 d-flex">
                    <img src={personImg} alt=""/>
                    <h4 className="modal-title text-white ms-2">Register1</h4>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                    <img className="virtualogin w-auto" src={require('../../assets/images/virtualogin.png')}
                         alt="virtualogin"/>
                </div>
            </Modal.Header>
            <Formik
                initialValues={{
                    first_name: '',
                    last_name: '',
                    username: '',
                    date_of_birth: ''
                }}
                enableReinitialize={true}
                innerRef={formRef}
                validationSchema={Yup.object().shape({
                    first_name: Yup.string().required("First Name is required"),
                    last_name: Yup.string().required("Last Name is required"),
                    username: Yup.string().required("Nick Name is required")
                })}
                onSubmit={_saveProfile}
            >
                {({isSubmitting}) => (
                    <>
                        <div className="modal-body row mx-0">
                            <Form>
                                <div className="row g-3">
                                    <div className="col">
                                        <div className="material-textfield">
                                            <Field
                                                type="text"
                                                name="first_name"
                                                placeholder="First Name"
                                            />
                                            <label>First Name</label>
                                        </div>
                                        <ErrorMessage name="first_name" component="span" className="invalid-feedback">
                                            {msg => <div style={{color: 'red'}} className="font-14">{msg}</div>}
                                        </ErrorMessage>
                                    </div>
                                    <div className="col">
                                        <div className="material-textfield">
                                            <Field
                                                type="text"
                                                name="last_name"
                                                placeholder="Last Name"/>
                                            <label>Last Name</label>
                                        </div>
                                        <ErrorMessage name="last_name" component="span" className="invalid-feedback">
                                            {msg => <div style={{color: 'red'}} className="font-14">{msg}</div>}
                                        </ErrorMessage>
                                    </div>
                                </div>
                                <div className="col mt-4">
                                    <div className="material-textfield">
                                        <Field
                                            type="text"
                                            name="username"
                                            placeholder="NickName"
                                        />
                                        <label>Nickname</label>
                                    </div>
                                    <ErrorMessage name="username" component="span" className="invalid-feedback">
                                        {msg => <div style={{color: 'red'}} className="font-14">{msg}</div>}
                                    </ErrorMessage>
                                </div>
                                <div className="col mt-4">
                                    <div className="material-textfield">
                                        <Field
                                            type="date" id="date_of_birth" name="date_of_birth"
                                            max={new Date().toISOString().split('T')[0]}
                                            placeholder="Date of Birth"
                                        />
                                        <label>Date Of Birth</label>
                                    </div>
                                    <ErrorMessage name="date_of_birth" component="span" className="invalid-feedback">
                                        {msg => <div style={{color: 'red'}} className="font-14">{msg}</div>}
                                    </ErrorMessage>
                                </div>
                                <p className="text-muted mt-4 fw-bold">
                                    <small>SELECT AVATAR</small>
                                </p>
                                {renderMenuItems()}
                                <div className="col-md-12 my-1 d-flex">
                                    <button type="button" className="no-btn-small"  onClick={goBackProfile}>
                                        <img src={groupImg} alt="go back"/>
                                    </button>
                                    <button type="submit" className="login-btn ms-3 btn-hover color-1">
                                        {isSubmitting ?
                                            <div
                                                className="spinner-border spinner-border-sm text-warning"
                                                role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div> : 'Next'}
                                    </button>
                                </div>
                            </Form>
                        </div>
                        <div className="modal-footer border-0 mx-0">
                            <div className="row w-100" data-bs-target="#loginModel" data-bs-toggle="modal">
                                <div className="col-md-10 d-flex align-items-center">
                                    <img src={svgLoginImg} className="pe-3 w-auto" alt="Register"/>
                                    <div className="account">
                                        <p className="text-white mb-0">Don't have an account?</p>
                                        <h6>Login</h6>
                                    </div>
                                </div>

                                <div className="col-md-2 my-3 d-flex justify-content-end">
                                    <Link to="#">
                                        <img src={svgRegisterImg} alt="register-right-arrow" className="w-auto"/>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </>
                )}
            </Formik>
        </Modal>
    );
}