import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux';
import {friendActions} from "../../_store";
import {debounce} from "lodash";

import AddFriendModal from "../../components/layout/modal/AddFriendModal";
import BlockFriendModal from "../../components/layout/modal/BlockFriendModal";
import { toast } from 'react-toastify';

import userIconPlus from "../../components/assets/images/icon/icon-user-plus.svg";
import kingIcon from "../../components/assets/images/icon/king-icon.svg";
import flagIcon from "../../components/assets/images/icon/flag.svg";
import userIconWhite from "../../components/assets/images/icon/icon-user-white.svg";
import acceptIcon from "../../components/assets/images/icon/accept.svg";
import rejectIcon from "../../components/assets/images/icon/reject.svg";
import addBlockPlus from "../../components/assets/images/icon/add-block-plus.svg";
import unBlockIcon from "../../components/assets/images/icon/unblock.svg";
import SelectGameModal from "../../components/layout/modal/SelectGameModal";

export default  function Friends() {

    const [activeTab, setActiveTab] = useState('friend-list-tab');
    const [addFriendModal, setAddFriendModal] = useState(false);
    const [blockFriendModal, setBlockFriendModal] = useState(false);
    const [friendSearch, setFriendSearch] = useState("");
    const [blockfriendSearch, setBlockfriendSearch] = useState("");
    const [selectGameModal, setSelectGameModal] = useState("");

    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const authProfile = useSelector(x => x.auth.user);
    const friends = useSelector(x => x.friends.friends);
    const pendingRequestFriends = useSelector(x => x.friends.pendingRequestFriends);
    const blockFriends = useSelector(x => x.friends.friendBlockList);

    useEffect(() => {
        getActiveData()
    }, [dispatch]);

    const getActiveData = (tab) => {
        const values = {
            profile: authProfile?.profile_link,
            appsId: authUser?.appInfo?.id
        }
        if (tab === 'friend-list-tab') {
            getFriendsList();
        } else if (tab === 'request-pending') {
            dispatch(friendActions.getPendingRequestFriendsList({values}));
        } else if (tab === 'block-list-tab') {
            getBlockFriendsList();
        } else {
            return false
        }
    }

    const closeSelectGameModal = () => {
        setSelectGameModal(false)
    }

    const getFriendsList = (rest = {}) => {
        const values = {
            profile: authProfile?.profile_link,
            appsId: authUser?.appInfo?.id,
            search: friendSearch,
            ...rest,
        }
        dispatch(friendActions.getFriendsList({values}));
    }

    const getBlockFriendsList = (rest = {}) => {
        const values = {
            profile: authProfile?.profile_link,
            appsId: authUser?.appInfo?.id,
            search: blockfriendSearch,
            ...rest
        }
        dispatch(friendActions.getBlockFriendsList({values}));
    }

    const closeAddFriendModal = () => {
        setAddFriendModal(false);
    }

    const closeBlockFriendModal = () => {
        setBlockFriendModal(false);
    }

    /* Unfriend Request */
    const unFriendRequest = (item) => {
        const values = {
            profile: authProfile?.profile_link,
            appsId: authUser?.appInfo?.id,
            friend_id: item.id
        }
        return dispatch(friendActions.removeFriend({values}))
            .then(()=>getActiveData('friend-list-tab'))
    }

    /*  Request Pending Start*/
    const acceptFriendRequest = (item) => {
        const values = {
            profile: authProfile?.profile_link,
            appsId: authUser?.appInfo?.id,
            friend_id: item.id
        }

        dispatch(friendActions.acceptFriendRequest({values})).then((response) => {
            if (response.payload?.message) {
                toast.success(response.payload.message);
                getActiveData("request-pending");
            }
            if (response?.error) {
                toast.error(response.error.message);
            }
        });
    }
    const rejectFriendRequest = (item) => {
        const values = {
            profile: authProfile?.profile_link,
            appsId: authUser?.appInfo?.id,
            friend_id: item.id
        }

        dispatch(friendActions.declineFriendRequest({values})).then((response) => {
            if (response.payload?.message) {
                toast.success(response.payload.message);
                getActiveData("request-pending");
            }
            if (response?.error) {
                toast.error(response.error.message);
            }
        });
    }
    /*  Request Pending End */

    /* Unblock Request */
    const unBlockFriendRequest = (item) => {
        const values = {
            profile: authProfile?.profile_link,
            appsId: authUser?.appInfo?.id,
            block_user_id: item.id
        }
        return dispatch(friendActions.removeBlockListFriend({values}))
            .then(()=>getActiveData("block-list-tab"))
    }

    const handleSearch = (event) => {
        setFriendSearch(event.target.value)
        getFriendsList({search:event.target.value})
    }
    const debouncedSearch = debounce(handleSearch, 300);

    const handleSearchBlockUser = (event) => {
        setBlockfriendSearch(event.target.value);
        getBlockFriendsList({search: event.target.value});
    }

    const debouncedSearchBlockUser =  debounce(handleSearchBlockUser, 300);
    return (
        <>
        <div className="friend-body-content">
            <div className="row py-4">
                <div className="col-md-3">
                    <div className="friend-heading text-white text-uppercase ms-2">
                        <h1>Friends</h1>
                    </div>
                </div>
                <div className="col-md-9">
                    <ul className="nav nav-tabs leaderboard border-0 justify-content-end" id="myTab" role="tablist">
                        <li className="nav-item me-2" role="presentation">
                            <button className={"nav-link border-0 border-left-radius text-white " + (activeTab === 'friend-list-tab' ? 'active' : '')}  onClick={() => {setActiveTab('friend-list-tab');getActiveData('friend-list-tab')}}
                                    id="friend-list-tab1" data-bs-toggle="tab" data-bs-target="#friend-list-tab"
                                    type="button" role="tab" aria-controls="friend-list-tab"
                                    aria-selected="true">Friend List
                            </button>
                        </li>
                        <li className="nav-item me-2" role="presentation">
                            <button className={"nav-link border-0 text-white " + (activeTab === 'request-pending' ? 'active' : '')}  onClick={() => {setActiveTab('request-pending');getActiveData('request-pending')}}
                                    id="request-pending1" data-bs-toggle="tab"
                                    data-bs-target="#request-pending" type="button" role="tab"
                                    aria-controls="request-pending" aria-selected="false">Request Pending
                            </button>
                        </li>
                        <li className="nav-item me-2" role="presentation">
                            <button className={"nav-link border-0 border-right-radius text-white " + (activeTab === 'block-list-tab' ? 'active' : '')}  onClick={() => {setActiveTab('block-list-tab');getActiveData('block-list-tab')}}
                                    id="block-list1"
                                    data-bs-toggle="tab" data-bs-target="#block-list-tab" type="button" role="tab"
                                    aria-controls="block-list-tab" aria-selected="false">Block List
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="tab-content px-2" id="myTabContent">
                <div className={"tab-pane fade show " + (activeTab === 'friend-list-tab' ? 'active' : '')}  id="friend-list-tab" role="tabpanel" aria-labelledby="friend-list-tab1" tabIndex="0">
                    <div className="modal-body border-0 mx-0 d-block">
                        <div className="row">
                            <div className="col-md-12 table-responsive">
                                <div className="table-top-search">
                                    <div className="row">
                                        <div className="col-md-10">
                                            <div className="table-search">
                                                <div className="input-group align-items-center">
                                                    <input
                                                        type="text"
                                                        className="form-control rounded text-white border-0"
                                                        placeholder="Search"
                                                        onChange={debouncedSearch}
                                                    />
                                                    <i className="fa-solid fa-magnifying-glass mt-0"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="add-friend-btn text-center">
                                                <button
                                                    type="button" className="btn-hover color-1 d-flex gap-2 align-items-center justify-content-center"
                                                    onClick={() => setAddFriendModal(true)}
                                                >
                                                    <img className="w-auto" src={userIconPlus} alt="add friend icon"/>
                                                    Add Friend
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <table className="table w-100 text-white plalyer-list">
                                    <tbody className="align-middle">
                                    {friends.length === 0 &&
                                        <tr>
                                            <td className="text-center text-white-50">No friends available</td>
                                        </tr>
                                    }
                                    {friends && friends.map((item,index) => (
                                        <tr key={index}>
                                            <td className="player-name ps-3 col-2">
                                                <img className="me-2" src={item.profile_picture} alt=""/> {item.first_name} {item.last_name}
                                            </td>
                                            <td className="green-color col-4">Level 11</td>
                                            <td className="unfriend-btn col-1">
                                                <div className="d-flex gap-2">
                                                    <button
                                                        className={"btn border-0 btn-hover color-1 py-2 px-3 font-13 fw-bold"}
                                                        style={{backgroundColor: "#42dbcb"}}
                                                        onClick={() => setSelectGameModal(true)}
                                                    >
                                                        Invite to play
                                                    </button>
                                                    <button className="text-white text-center btn-hover color-3"
                                                            onClick={() => unFriendRequest(item)}>
                                                        <img className="w-auto" src={userIconWhite} alt=""/> Unfriend
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"tab-pane fade show " + (activeTab === 'request-pending' ? 'active' : '')}
                     id="request-pending" role="tabpanel"
                     aria-labelledby="request-pending1" tabIndex="0">
                    <div className="modal-body border-0 mx-0 d-block">
                        <div className="row">
                            <div className="col-md-12 table-responsive">
                                <table className="table w-100 text-white plalyer-list">
                                <tbody className="align-middle">
                                    {pendingRequestFriends.length === 0 &&
                                        <tr>
                                            <td className="text-center text-white-50">No Pending Request available</td>
                                        </tr>
                                    }
                                    {pendingRequestFriends && pendingRequestFriends.map((item,index) => (
                                      <tr key={index}>
                                        <td className="player-name ps-3 col-3"><img className="me-2" src={item.profile_picture} alt=""/> {item.first_name} {item.last_name}</td>
                                        <td className="green-color col-7">Level 11</td>
                                        <td className="accept-btn">
                                            <button className="text-center btn-hover color-4" onClick={() => acceptFriendRequest(item)}>
                                                <img className="w-auto" src={acceptIcon} alt="accept btn"/> Accept
                                            </button>
                                        </td>
                                            <td className="reject-btn">
                                                <button className="text-white text-center btn-hover color-3" onClick={() => rejectFriendRequest(item)}>
                                                    <img className="w-auto" src={rejectIcon} alt="reject btn"/> Reject
                                                </button>
                                            </td>
                                       </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"tab-pane fade show " + (activeTab === 'block-list-tab' ? 'active' : '')} id="block-list-tab" role="tabpanel"
                     aria-labelledby="block-list-tab1" tabIndex="0">
                    <div className="modal-body border-0 mx-0 d-block">
                        <div className="row">
                            <div className="col-md-12 table-responsive">
                                <div className="table-top-search">
                                    <div className="row">
                                        <div className="col-md-10">
                                            <div className="table-search">
                                                <div className="input-group align-items-center">
                                                    <input
                                                       type="text"
                                                       className="form-control rounded text-white border-0"
                                                       placeholder="Search"
                                                       onChange={debouncedSearchBlockUser}
                                                    />
                                                    <i className="fa-solid fa-magnifying-glass mt-0"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="add-block-list text-center">
                                                <button
                                                    type="button" className="btn-hover color-5 d-flex gap-2 align-items-center justify-content-center"
                                                    onClick={() => setBlockFriendModal(true)}
                                                >
                                                    <img className="w-auto" src={addBlockPlus} alt="add block list icon"/>
                                                    Add to Block List
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <table className="table w-100 text-white plalyer-list">

                                    <tbody className="align-middle">
                                    {blockFriends.length === 0 &&
                                    <tr>
                                        <td className="text-center text-white-50">No block user available</td>
                                    </tr>
                                    }
                                    {blockFriends && blockFriends.map((item,index) => (
                                        <tr key={index}>
                                            <td className="player-name ps-3 col-3">
                                                <img className="me-2" src={item.profile_picture} alt=""/>
                                                {item.first_name} {item.last_name}
                                            </td>
                                            <td className="green-color w-50">Level 11</td>
                                            <td className="unblock-btn">
                                                <button className="text-center btn-hover color-1" onClick={() => unBlockFriendRequest(item)}>
                                                    <img className="w-auto" src={unBlockIcon} alt=""/> Unblock
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AddFriendModal isOpen={addFriendModal} handleClose={closeAddFriendModal} />
        <BlockFriendModal isOpen={blockFriendModal} handleClose={closeBlockFriendModal}/>
            <SelectGameModal isOpen={selectGameModal} handleClose={closeSelectGameModal}/>
        </>
    );
}


