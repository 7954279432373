import React, {useEffect, useState} from 'react';
import featherUserIcon from "../../assets/images/icon/Icon-feather-users.svg";
import dotIcon from "../../assets/images/icon/dot.svg";
import sendIcon from "../../assets/images/icon/icon-send.svg";
import {addDoc, collection, onSnapshot, query, serverTimestamp} from "firebase/firestore";
import {db} from "../../../firebase-config";
import _ from "lodash";
import {useSelector} from "react-redux";
import {timeCalculate} from "../../../_helpers";
import {useParams} from "react-router-dom";

const GameChatSection = () => {
    const {id} = useParams()
    const [message, setMessage] = useState('');
    const [messagesLists, setMessages] = useState([]);
    const authUser = useSelector(x => x.auth.user);

    useEffect(() => {
        const roomInfo = collection(db, window.location.pathname.split("/").pop());
        const queryMessages = query(roomInfo);
        const unsubscribe = onSnapshot(queryMessages, (snapshot) => {
            let messages = []
            snapshot.forEach((doc) =>  {
                if (doc.data().room === window.location.pathname.split("/").pop()) {
                    messages.push({...doc.data()});
                }
            })
            const sortedData = _.sortBy(messages, 'createdAt');
            setMessages([...sortedData])

            scrollToBottom(200);
        })
        return () => {
            unsubscribe();
            setMessages([]);
        }
    }, [window.location.pathname]);

    const scrollToBottom = (timeout = 200) => {
        setTimeout(()=>{
            document.querySelector(".bottom-line")?.scrollIntoView({behavior:"auto"});
        },timeout)
    }

    const sendMessage = async (event) => {
        event.preventDefault();
        if (message && id) {
            const roomInfo = collection(db, String(id));
            const currentDate = serverTimestamp();
            const user = _.pick(authUser,["first_name","last_name","user_id","email","profile_picture"]);
            setMessage("");
            await addDoc(roomInfo, { message, user, room: id , createdAt:currentDate }).then(()=> scrollToBottom(100))
        }
    }
    return (
        <div
            className="col-lg-4 col-md-4 border mb-5 border-secondary rounded-4"
            style={{backgroundColor: "#161616"}}
        >
            <div className="row">
                <div className="col-lg-12 col-md-12 border-bottom border-secondary px-4 py-3">
                    <div className="row">
                        <div className="col-lg-7 col-md-7">
                            <p className="font-22 font-oval-single color-green mb-0">
                                Lobby
                            </p>
                        </div>
                        <div className="col-lg-5 col-md-5 text-end">
                            <p className="fw-bold font-13 mb-0 text-white pt-2">
                          <span>
                            <img
                                src={featherUserIcon}
                                style={{width: "15px"}}
                            />
                          </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    height: "500px",
                    overflow: "auto"
                }}
            >
                {messagesLists.map((message, index) => (
                    <>
                        {(_.get(message, "user.user_id") === _.get(authUser, "user_id")) ? (
                            <div className="row mt-5 text-end mx-0" key={index}>
                                <div className="col-lg-2 col-md-2 clearfix"></div>
                                <div className="col-lg-10 col-md-10 px-4">
                                    <div className="row">
                                        <p className="text-secondary font-10 mb-1 pe-0">
                                            {message.createdAt ? timeCalculate(message.createdAt.toDate()) : ""}{" "}
                                            <span className="text-muted">
                                              <img src={dotIcon} alt="" style={{width: "5px"}}/> You
                                            </span>
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="game-info-chat-own-section background-dark-green message-1">
                                            <p className="text-white font-13 mb-0">
                                                {message.message}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="row mt-4 mx-0" key={index}>
                                <div className="col-lg-2 col-md-2 ps-4">
                                    <img
                                        src={_.get(message, "user.profile_picture", "")}
                                        style={{width: "50px"}}
                                        alt="image"
                                    />
                                </div>
                                <div className="col-lg-10 col-md-10 px-4">
                                    <div className="row">
                                        <p className="text-secondary ps-0 font-10 mb-1">
                                            {_.get(message, "user.first_name", "")} {_.get(message, "user.last_name", "")}{" "}
                                            <span className="text-muted">
                                              <img src={dotIcon} alt="dotIcon" style={{width: "5px"}}/> {message.createdAt ? timeCalculate(message.createdAt.toDate()) : ""}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="game-info-chat-others-section background-dark-yellow message-2">
                                            <p className="text-white font-13 mb-0">
                                                {message.message}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                ))}
                <div className="bottom-line"/>
            </div>
            {authUser && (
                <form className="row mt-3">
                    <div className="d-flex players-chat-scroll">
                        <input
                            type="text"
                            name="name"
                            className={"game-message-input text-white"}
                            placeholder="Type here..."
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        <div className="send-msg d-flex align-items-center">
                            <button type="submit" className="btn btn-transparent p-0" onClick={sendMessage}>
                                <img className="w-auto" src={sendIcon} alt="send icon"/>
                            </button>
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
};

export default GameChatSection;